import React, { useState, useEffect } from 'react';
import { Button, Card, CardContent, Typography, Grid } from '@mui/material';
import useDocumentTitle from './hooks/useDocumentTitle';
import usePageTracking from './hooks/usePageTracking';

const CancelSubscriptionPage = ({ userId }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);

    useDocumentTitle('Account Details');
    usePageTracking();

    // Fetch subscription details to display to the user
    useEffect(() => {
        fetch(`/api/subscription-details/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            setSubscriptionDetails(data);
            setIsLoading(false);
        })
        .catch(error => {
            console.error('Error:', error);
            setIsLoading(false);
        });
    }, [userId]);

    const cancelSubscription = () => {
        fetch('/api/cancel-subscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.error) {
                alert('Failed to cancel subscription: ' + data.error);
            } else {
                alert('Your subscription has been successfully cancelled.');
                // Refresh subscription details to reflect the updated status
                fetch(`/api/subscription-details/${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => response.json())
                .then(updatedData => {
                    setSubscriptionDetails(updatedData);
                })
                .catch(error => console.error('Error:', error));
            }
        })
        .catch(error => console.error('Error:', error));
    };

    if (isLoading) return <div>Loading...</div>;

    const hasActiveSubscription = subscriptionDetails && subscriptionDetails.status === 'active';

    return (
        <Grid container spacing={2} justifyContent="center" sx={{ p: 3 }}>
            <Grid item xs={12} sm={6} md={4}>
                <Card 
                    raised 
                    sx={{
                        minHeight: 200,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        backgroundColor: 'background.paper',
                        transform: 'scale(1)',
                        transition: 'transform .2s'
                    }}
                >
                    <CardContent>
                        {hasActiveSubscription ? (
                            <>
                                <Typography variant="h5" component="div" gutterBottom>
                                    Subscription Details
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    {subscriptionDetails?.productName}
                                </Typography>
                                {subscriptionDetails?.cancelAtPeriodEnd ? (
                                   <Typography variant="body1" color="text.secondary">
                                    Subscription is set to cancel at the end of the billing period on {
                                        new Date(subscriptionDetails?.nextBillingDate * 1000)
                                            .toLocaleDateString("en-US", {
                                                year: 'numeric', month: 'long', day: 'numeric'
                                            })
                                    }.
                                </Typography>
                                ) : (
                                    <>
                                        <Typography variant="body1">
                                            Status: {subscriptionDetails?.status}
                                        </Typography>
                                        <Typography variant="body1">
                                        Next Billing Date: {
                                            new Date(subscriptionDetails?.nextBillingDate * 1000)
                                                .toLocaleDateString("en-US", {
                                                    year: 'numeric', month: 'long', day: 'numeric'
                                                })
                                        }
                                    </Typography>
                                        <Button 
                                            size="large" 
                                            variant="contained" 
                                            color="primary" 
                                            onClick={cancelSubscription}
                                            sx={{ margin: 2 }}
                                        >
                                            Cancel Subscription
                                        </Button>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <Typography variant="h5" component="div" gutterBottom>
                                    No Active Subscription
                                </Typography>
                                <Typography variant="body1">
                                    You do not have an active subscription to cancel.
                                </Typography>
                            </>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default CancelSubscriptionPage;
