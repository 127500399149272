import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA4 from 'react-ga4';

function usePageTracking() {
  const location = useLocation();
  useEffect(() => {
 //   console.log("Tracking page view for:", location.pathname); // Add this line for debugging
    ReactGA4.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);
}

export default usePageTracking;
