
import React, { useState, useEffect } from 'react';
import { signInWithGoogle, auth, db } from './firebase'; // Ensure this path points to your Firebase config
import { createUserWithEmailAndPassword, sendEmailVerification, GoogleAuthProvider, signInWithCredential, getAuth } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore'; // Removed collection import
import { useNavigate } from 'react-router-dom'; // assuming you're using react-router v6
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import useDocumentTitle from './hooks/useDocumentTitle'; // Adjust the path as necessary
import usePageTracking from './hooks/usePageTracking'; // Adjust the path as necessary
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';



const defaultTheme = createTheme();

export default function SignUp() {
    useDocumentTitle('Sign Up for FirstPic - AI Photo Rating for Dating Apps');
    usePageTracking();
    useEffect(() => {
        // Dynamically load the Google Identity Services library
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.onload = () => {
            // Initialize the Google Sign-In button once the library is loaded
            window.google.accounts.id.initialize({
                client_id: '725036592928-m11mt5bjgjmli810aa2km1rjfo6c9haa.apps.googleusercontent.com', // Replace with your client ID
                callback: handleCredentialResponse,
            });
            window.google.accounts.id.renderButton(
                document.getElementById('googleSignUpButton'), // Element ID where the button will be rendered
                { theme: 'outline', size: 'large' }  // Button customization options
            );
        };
        document.body.appendChild(script);

        // Cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

const handleCredentialResponse = async (response) => {
    const credential = GoogleAuthProvider.credential(response.credential);
    const auth = getAuth();

    try {
        const userCredential = await signInWithCredential(auth, credential);
        const user = userCredential.user;

        // Call to your backend to create the user in MongoDB with gender set to 'other'
        const response = await fetch(`${backendURL}/api/createUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: user.uid, gender: 'other' }), // Setting gender to 'other'
        });

        const data = await response.json();
        if (response.ok) {
            // Optionally handle the response data
            navigate('/photo-upload');
        } else {
            // Handle server errors (e.g., user already exists)
            throw new Error(data.error || 'Failed to create user account.');
        }
    } catch (error) {
        console.error('Error signing up with Google:', error);
        setErrorMessage(error.message);
    }
};


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState(''); // For verifying password
    const [passwordError, setPasswordError] = useState(''); // For password match error
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [gender, setGender] = useState('');
    const [infoMessage, setInfoMessage] = useState(''); // For success/info messages
    const [errorMessage, setErrorMessage] = useState(''); // For error messages
    const [genderError, setGenderError] = useState('');
    const [emailOptIn, setEmailOptIn] = useState(true); // Set initial state to true

    const navigate = useNavigate();

    const darkTheme = createTheme({
        palette: {
            mode: 'dark', // Switch to dark mode
            primary: {
                main: '#90caf9', // Adjust primary color as needed
            },
            secondary: {
                main: '#f48fb1', // Adjust secondary color as needed
            },
            background: {
                default: '#121212', // Dark background color
                paper: '#1e1e1e', // Dark paper color
            },
        },
    });

const handleSignUp = async (event) => {
    event.preventDefault();
    setInfoMessage(''); // Reset info message on new submission
    setErrorMessage(''); // Reset error message on new submission
    setGenderError(''); // Reset gender error on new submission
    if (password !== confirmPassword) {
        setPasswordError('Passwords do not match.');
        return; // Prevent form submission if passwords do not match
    }
    // Validate gender
    if (!gender) {
        setGenderError('Please select your gender.');
        return; // Prevent form submission if gender is not selected
    }
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Send email verification
        await sendEmailVerification(user);

        // Add a new document in collection "users"
        await setDoc(doc(db, 'users', user.uid), {
            email: email,
            gender: gender, // Include gender here
            emailMarketingOptIn: emailOptIn,
            emailVerified: false, // Set emailVerified to false initially
            // any other user details
        });

        // Add a new call to your backend to create the user in MongoDB
        fetch(`${backendURL}/api/createUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: user.uid, gender }),
        })
        .then(response => response.json())
        .then(data => {
            // Handle response
        })
        .catch(error => {
            console.error('Error creating user in MongoDB:', error);
        });

        // Let the user know they need to verify their email
        setInfoMessage('We just sent a verification email to your inbox. Please click the link to verify and sign in.');

        // Optionally, navigate to a "check email" page or stay on the same page
        // navigate('/check-email');

    } catch (err) {
        // Custom error handling
        const errorMessage = getCustomErrorMessage(err.code);
        setErrorMessage(errorMessage); // Use setErrorMessage for actual errors
    }
};


// Firebase error code to custom message mapping
const getCustomErrorMessage = (errorCode) => {
    const errorMessages = {
        'auth/email-already-in-use': 'This email address is already in use. Please sign in or use a different email.',
        'auth/invalid-email': 'The email address is not valid. Please enter a valid email address.',
        'auth/operation-not-allowed': 'Operation not allowed. Please contact support.',
        'auth/weak-password': 'The password is too weak. Please use a stronger password.',
        // Add more Firebase error codes here as needed
    };

    return errorMessages[errorCode] || 'An unexpected error occurred. Please try again.';
};


return (
        <ThemeProvider theme={darkTheme}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: (theme) => theme.spacing(1, 2) // Responsive padding
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSignUp} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            {/* Additional fields like First Name, Last Name can be added here */}
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="new-password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    error={!!passwordError}
                                    helperText={passwordError}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    id="confirmPassword"
                                    autoComplete="new-password"
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    error={!!passwordError}
                                    helperText={passwordError}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle confirm password visibility"
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <FormControl fullWidth error={!!genderError}>
                                <InputLabel id="gender-select-label">Gender*
                                </InputLabel>
                                <Select
                                    labelId="gender-select-label"
                                    id="gender"
                                    value={gender}
                                    label="Gender *"
                                    onChange={(e) => {
                                        setGender(e.target.value);
                                        setGenderError(''); // Reset gender error when user selects a gender
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'male'}>Male</MenuItem>
                                    <MenuItem value={'female'}>Female</MenuItem>
                                    <MenuItem value={'other'}>Other</MenuItem>
                                </Select>
                                {genderError && <FormHelperText>{genderError}</FormHelperText>}
                                <FormHelperText>
                                    We ask for this so we can show you how your photos compare to users of the same gender.
                                </FormHelperText>
                            </FormControl>
                        </Grid>



                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={emailOptIn} // Set checkbox to be checked by default
                                            value={emailOptIn}
                                            color="primary"
                                            onChange={(e) => setEmailOptIn(e.target.checked)}
                                        />
                                    }
                                    label="I want to receive tips, promotions and updates via email."
                                />
                            </Grid>
                        </Grid>
                        {infoMessage && <Alert severity="success">{infoMessage}</Alert>} {/* Use infoMessage and severity="success" */}
                        {errorMessage && <Alert severity="error">{errorMessage}</Alert>} {/* Use errorMessage and severity="error" */}                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Up
                        </Button>
                                   <div id="googleSignUpButton" style={{ marginTop: 20, marginBottom: 20 }}></div>


                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href="/signin" variant="body2">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}