import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const SeenOn = ({ logos }) => {
  const styles = {
    logoContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      marginTop: '20px',
      marginBottom: '20px',
    },
    logo: {
      height: '50px',
      maxWidth: '150px',
      width: 'auto',
    },
    mobileLogo: {
      height: '30px'
    },
    outerContainer: {
      backgroundColor: '#f5f5f5', // Light gray background
      padding: '20px', // Padding around the content
      borderRadius: '8px' // Rounded corners for aesthetics
    },
    seenOnText: {
      textAlign: 'center',
      color: 'black', // Text color set to black
    }
  };

  return (
    <Box sx={styles.outerContainer}>
      <Typography variant="h6" component="h2" sx={styles.seenOnText}>
        Seen On:
      </Typography>
      <Box sx={styles.logoContainer}>
        {logos.map((logo, index) => (
          <Box
            component="img"
            sx={{
              ...styles.logo,
              '@media (max-width:768px)': styles.mobileLogo
            }}
            src={logo.src}
            alt={logo.alt}
            key={index}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SeenOn;
