import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import useDocumentTitle from './hooks/useDocumentTitle';
import usePageTracking from './hooks/usePageTracking';

const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

const CheckOutComplete = () => {
  useDocumentTitle('Thank You for Your Purchase - Bios!');
  usePageTracking();
  const navigate = useNavigate();

  useEffect(() => {
    const pendingBio = localStorage.getItem('pendingBio');

    // Define an async function to submit the bio
    const submitBio = async () => {
      const pendingBio = JSON.parse(localStorage.getItem('pendingBio'));
      if (pendingBio) {
        try {
          const response = await fetch(`${backendURL}/api/submitBio`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(pendingBio)
          });

          const data = await response.json();
          if (response.ok) { // Assuming `response.ok` is true if submission was successful
            navigate(`/bio-display/${data.bioId}`);
          } else {
            console.error('Bio submission was not successful:', data);
            // Handle unsuccessful submission, e.g., show error message
          }
        } catch (error) {
          console.error('Error submitting bio:', error);
          // Handle fetch error, e.g., network issues
        }
      } else {
        console.error('Missing bio information');
        // Handle missing bio information case, e.g., navigate to an error page or show error message
      }
    };

    submitBio();
  }, [navigate]);

  return (
    <Box style={{ textAlign: 'center', marginTop: '100px' }}>
      <Typography variant="h4">Thank You for Your Purchase!</Typography>
      <Typography variant="subtitle1">Your bio will be processed shortly.</Typography>
      <CircularProgress style={{ marginTop: '20px' }} />
    </Box>
  );
};

export default CheckOutComplete;
