import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import useDocumentTitle from './hooks/useDocumentTitle'; // Adjust the path as necessary
import usePageTracking from './hooks/usePageTracking'; // Adjust the path as necessary
const StyledTipsContainer = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh',
    backgroundColor: '#121212', // Dark background
    color: '#ffffff', // White text for better contrast
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center align the items
    justifyContent: 'flex-start', // Adjust content to start from the top
}));

const TipsList = styled('ul')(({ theme }) => ({
    listStyle: 'none', // Remove default list styling
    padding: 0,
    color: '#ffffff', // Ensure list text is white
    textAlign: 'left', // Left align text
    maxWidth: '600px', // Set a max-width for better readability
}));

const TipsPage = () => {
    useDocumentTitle('Tips');
    usePageTracking();
    const theme = useTheme(); // Using useTheme hook

    return (
        <StyledTipsContainer>
            <Typography variant="h4" gutterBottom style={{ marginBottom: theme.spacing(2) }}>
                How To Pick Your Photos
            </Typography>
            <Typography variant="body1" style={{ marginBottom: theme.spacing(4) }}>
                🌟 Hey there! Here are some tips for selecting the best pictures to boost your matches:
            </Typography>
            <TipsList>
                <li><Typography variant="body2">📸 <strong>Quality Matters:</strong>  Ensure your photo is crisp! Good lighting, sharp focus, and a good camera (a newer smartphone in portrait mode does the trick!) are essential.</Typography></li>
                <li><Typography variant="body2">🚶‍♂️ <strong>Full Body Shot:</strong> Let's see all of you! Photos showcasing your entire silhouette tend to do better.</Typography></li>
                <li><Typography variant="body2">👀 <strong>Eyes on the Prize:</strong> Ditch those sunglasses and let your eyes shine! Remember, a smile isn't just for lips – let your eyes do the talking too.</Typography></li>
                <li><Typography variant="body2">🌆 <strong>Backdrop Magic:</strong> Say no to dull apartment walls. Opt for vibrant backdrops, be it nature, art, or cool architecture.</Typography></li>
                <li><Typography variant="body2">🤸 <strong>Personality Pop:</strong> Show off a bit of 'you'! Whether it's laughing, dancing, or showcasing a hobby, let your personality shine through.</Typography></li>
                <li><Typography variant="body2">💬 <strong>Conversation Starters:</strong> Give your matches something to chat about! Holding a book or any other prop can be fun – just, no fish, please! 🚫🐟</Typography></li>
                <li><Typography variant="body2">👩‍🎤 <strong>Solo Star:</strong> It's all about you! Group shots might be fun but tend to score lower. Shine solo!</Typography></li>
                <li><Typography variant="body2">🤳 <strong>Skip the Selfies:</strong> As tempting as they are, especially for the gents, selfies tend not to fare as well.</Typography></li>
            </TipsList>
            <Typography variant="body1" style={{ marginTop: theme.spacing(4) }}>
                Now, select the best that you've got, and we'll show you which ones are best and how to order them! 🌟📷🚀
            </Typography>
        </StyledTipsContainer>
    );
};

export default TipsPage;
