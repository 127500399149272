// FAQComponent.js
import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
  {
    question: 'What is FirstPic?',
    answer:
      'FirstPic uses AI to solve the problem of building a dating app profile and messaging on dating apps. We are the only AI that has been trained on tens of thousands of photos to identify the qualities of dating pics that get you stunning match quality and quantity. We also have studied how to craft premium dating app bios, prompts and messages for dating apps like Tinder, Bumble and Hinge using just a few details from you.',
  },
  {
    question: 'How does photo analysis work?',
    answer:
      'FirstPic employs sophisticated artificial intelligence-powered algorithms to grade the suitability of your pics for dating apps. In addition, it will tell you how your photo compares to those of other users of the same gender, so you can finally know how you stack up against the competition!',
  },  
  {
    question: 'Does FirstPic give me feedback on individual photos?',
    answer:
      'Yes! Our sophisticated AI uses deep learning models to analyze your photo and give feedback on how it could be improved.',
  },
  {
    question: 'Will FirstPic help me improve my photos?',
    answer:
      'Yes! FirstPic has a tips section that walks you through how to snap that perfect photo for your dating profile.',
  },
  {
    question: 'How does dating app bio and prompt creation work?',
    answer:
      'FirstPic asks you to answer a few quick questions about yourself and then uses specially trained AI that knows how to write engaging content for dating app bios and prompts to write your dating app content. You can select whether the content should be written for Tinder, Bumble or Hinge.',
  },
  {
    question: 'How does messaging work?',
    answer:
      'You can ask our messaging assistant to write you a message to send you match based on thier profile or an existing converation. You can easily copy this message and paste it into your chat. Our messaging assistant is trained to help lead engaging conversation that help you move things off app and land dates!',
  },
  {
    question: 'Is my data safe?',
    answer:
      'We don\'t sell or share your data, period. We only use it to make the app better and fix any issues. Your trust and safety are our top priority.',
  },
  {
    question: 'Does photo analysis measure my attractiveness?',
    answer:
      'No. Our photo analysis is designed to grade your photo based on what goes into a great first pic for apps like Tinder, Bumble, and Hinge. Your looks are not important - how you\'re marketing and presenting your amazing self is what our algorithm quantifies.',
  },
  {
    question: 'How much does FirstPic cost?',
    answer:
      'Comprehensive photo analysis and dating app content creation is $6.00, billed as a monthly subscription (cancel any time). You can try our photo analysis for free! If you just want to purchase photo analysis, we have packs of photo ratings in increments of 5 ($2.99), 10 ($3.99) or 15 ($4.99). All purchases are processed through Stripe.',
  },  
];

const FAQComponent = () => {
  return (
    <div>
      {faqs.map((faq, index) => (
        <Accordion key={index} style={{ backgroundColor: '#1e1e1e', color: 'white' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Typography align="left">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography align="left">{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FAQComponent;
