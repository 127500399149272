import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, setPersistence, browserSessionPersistence, browserLocalPersistence, sendEmailVerification, GoogleAuthProvider, signInWithCredential } from 'firebase/auth';
import { signInWithGoogle, auth, db } from './firebase';
import { useNavigate } from 'react-router-dom'; // assuming you are using react-router v6
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useDocumentTitle from './hooks/useDocumentTitle'; // Adjust the path as necessary
import usePageTracking from './hooks/usePageTracking'; // Adjust the path as necessary


const defaultTheme = createTheme();

export default function SignInSide() {
    useDocumentTitle('Sign In to FirstPic - AI Photo Rating for Dating Apps');
    usePageTracking();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [rememberMe, setRememberMe] = useState(false);
    useEffect(() => {
        // Dynamically load the Google Identity Services library
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.onload = () => {
            // Initialize the Google Sign-In button once the library is loaded
            window.google.accounts.id.initialize({
                client_id: '725036592928-m11mt5bjgjmli810aa2km1rjfo6c9haa.apps.googleusercontent.com', // Replace with your client ID
                callback: handleCredentialResponse,
            });
            window.google.accounts.id.renderButton(
                document.getElementById('signInDiv'), // Element ID where the button will be rendered
                { theme: 'outline', size: 'large' }  // Button customization options
            );
        };
        document.body.appendChild(script);

        // Cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    const darkTheme = createTheme({
        palette: {
            mode: 'dark', // Switch to dark mode
            primary: {
                main: '#90caf9', // Adjust primary color as needed
            },
            secondary: {
                main: '#f48fb1', // Adjust secondary color as needed
            },
            background: {
                default: '#121212', // Dark background color
                paper: '#1e1e1e', // Dark paper color
            },
        },
    });


const handleSubmit = async (event) => {
    event.preventDefault();
    const authInstance = getAuth(); // Get the Firebase Auth instance

    try {
        const persistenceType = rememberMe ? browserLocalPersistence : browserSessionPersistence;
        await setPersistence(authInstance, persistenceType); // Set persistence
        const userCredential = await signInWithEmailAndPassword(authInstance, email, password); // Sign in
        const user = userCredential.user;

        if (!user.emailVerified) {
            // User's email is not verified
            setError('Your email address has not been verified. Please check your inbox for the verification email or click "Resend" to get a new verification email.');
            // Optionally provide a button or link to resend the verification email
            // await sendEmailVerification(user); // Uncomment to send immediately
        } else {
            // User's email is verified, allow access to your application
            navigate('/welcome');
        }
    } catch (err) {
        setError(err.message);
    }
};

const handleCredentialResponse = (response) => {
    // This assumes you're using Firebase v9+ with modular syntax
    const auth = getAuth();
    const credential = GoogleAuthProvider.credential(response.credential);
    
    signInWithCredential(auth, credential).then((userCredential) => {
        // User is signed in, navigate or update state as needed
        navigate('/chat-interface');
    }).catch((error) => {
        // Handle errors
        setError(error.message);
    });
};

return (
        <ThemeProvider theme={darkTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={false}
                    md={7}
                    sx={{
                        display: { xs: 'none', md: 'block' }, // Hide on small screens
                        backgroundImage: 'url(https://source.unsplash.com/random?dating)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        
<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
    <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        onChange={(e) => setEmail(e.target.value)}
    />
    <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        onChange={(e) => setPassword(e.target.value)}
    />
        <div id="signInDiv"></div>


    {error && (
    <Box sx={{ mt: 2 }}>
        <Typography color="error">{error}</Typography>
        {error.includes('Your email address has not been verified.') && (
            <Button
                variant="outlined"
                sx={{ mt: 1 }}
                onClick={async () => {
                    const auth = getAuth();
                    const user = auth.currentUser;
                    if (user) {
                        await sendEmailVerification(user);
                        setError('Verification email sent. Please check your inbox.');
                    }
                }}
            >
                Resend Verification Email
            </Button>
        )}
    </Box>
)}

    <FormControlLabel
        control={<Checkbox checked={rememberMe} color="primary" onChange={() => setRememberMe(!rememberMe)} />}
        label="Remember me"
    />
    <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
    >
        Sign In
    </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="/reset-password" variant="body2">
                                    Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="/signup" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}