import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import useDocumentTitle from './hooks/useDocumentTitle'; // Adjust the path as necessary
import usePageTracking from './hooks/usePageTracking'; // Adjust the path as necessary

const BioHistory = ({ userId }) => {
    useDocumentTitle('Bio History');
    usePageTracking();
    const [bios, setBios] = useState([]);

    useEffect(() => {
        fetch(`/api/bioHistory/${userId}`)
            .then(response => response.json())
            .then(data => {
                const sortedBios = data.bios.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setBios(sortedBios);
            })
            .catch(error => console.error('Error fetching bio history:', error));
    }, [userId]);

    const renderContent = () => {
        if (bios && bios.length > 0) {
            return bios.map((bio, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {bio.platform}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                {bio.generatedText}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Created: {new Date(bio.createdAt).toLocaleDateString()}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ));
        } else {
            return (
                <Box sx={{ width: '100%', textAlign: 'center', marginTop: 2 }}>
                    <Typography variant="h6">
                        You haven't created any bios yet!
                    </Typography>
                </Box>
            );
        }
    };

    return (
        <Grid container spacing={2}>
            {renderContent()}
        </Grid>
    );
};

export default BioHistory;
