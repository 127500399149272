import React, { useState } from 'react';
import { Box, Button, CardActionArea, Typography, styled, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from './hooks/useDocumentTitle';
import usePageTracking from './hooks/usePageTracking';
import TipsPage from './TipsPage'; // Adjust the path as necessary to where your TipsPage component is located relative to this file

const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

const Input = styled('input')({
  display: 'none',
});

function resizeImage(file, maxWidth, maxHeight, quality = 0.5) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = () => {
      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0, width, height);

      canvas.toBlob((blob) => {
        resolve(new File([blob], file.name, {
          type: 'image/jpeg',
          lastModified: Date.now()
        }));
      }, 'image/jpeg', quality);
    };

    image.onerror = error => {
      reject(error);
    };
  });
}

function PhotoUpload({ userId }) {
  useDocumentTitle('Profile Builder');
  usePageTracking();
  const [photos, setPhotos] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const handlePhotoChange = async (e) => {
    const selectedPhotos = Array.from(e.target.files);
    const resizedPhotos = await Promise.all(selectedPhotos.map(photo => resizeImage(photo, 800, 800)));

    if (resizedPhotos.length > 10) {
      alert('You can upload a maximum of 10 photos. Only the first 10 photos will be uploaded.');
      setPhotos(resizedPhotos.slice(0, 10));
    } else {
      setPhotos(resizedPhotos);
    }
  };

  const handleSubmit = async () => {
    if (!userId) {
      console.error('User ID is not available.');
      return;
    }

    setIsProcessing(true);
    const response = await fetch(`${backendURL}/api/user-details/${userId}`);
    if (!response.ok) {
      console.error('Network response was not ok');
      setIsProcessing(false);
      return;
    }
    const { isSubscribed } = await response.json();

    if (isSubscribed) {
      const photoIds = photos.map(() => crypto.randomUUID());
      localStorage.setItem('photoIds', JSON.stringify(photoIds));

      const formData = new FormData();
      formData.append('userId', userId);
      photos.forEach((photo) => {
        formData.append('photos', photo);
      });

      formData.append('photoIds', JSON.stringify(photoIds));

      fetch(`${backendURL}/api/submitProfileBulk`, {
        method: 'POST',
        body: formData,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          console.log('Photos submitted successfully:', data);
          localStorage.setItem('photosData', JSON.stringify(data.photos));
          navigate('/photo-display'); // Navigate to PhotoDisplay after successful submission
        })
        .catch((error) => {
          console.error('Error submitting photos:', error);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    } else {
      navigate('/photos-payments-page');
      setIsProcessing(false);
    }
  };

  const renderPhotosUploadArea = () => (
    <CardActionArea sx={{ maxWidth: '100%', margin: 'auto', borderRadius: '20px', overflow: 'hidden', backgroundColor: 'transparent', padding: '0px' }}>
      <label htmlFor="upload-photo">
        <Input accept="image/*" id="upload-photo" type="file" multiple onChange={handlePhotoChange} />
        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          padding: '16px',
          border: '1px dashed grey',
          borderRadius: '20px',
          boxSizing: 'border-box',
          width: '100%',
          backgroundColor: 'transparent',
        }}>
          {photos.length > 0 ? (
            photos.map((photo, index) => (
              <img
                key={index}
                src={URL.createObjectURL(photo)}
                alt={`Uploaded ${index + 1}`}
                style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '10px' }}
              />
            ))
          ) : (
            <Typography variant="subtitle1">Click to upload images</Typography>
          )}
        </Box>
      </label>
    </CardActionArea>
  );

  return (
    <div>
      {/* Welcome Text */}
      <Typography variant="h5" sx={{ mb: 4 }}>
        Welcome to FirstPic! Let's build your profile for dating apps like Tinder, Bumble, and Hinge.
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        To start, upload your 10 best dating app photos.
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        At the end, you'll see feedback on your photos and how you should order them.
      </Typography>
      {/* Bulk Photo Upload Heading */}
      <Typography variant="body1" sx={{ mb: 4 }}>Upload Up to 10 Photos</Typography>
      {renderPhotosUploadArea()}
      {isProcessing ? (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
        <Typography variant="subtitle1">
            Analyzing images... one moment please.
        </Typography>
        <CircularProgress color="primary" />
    </Box>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={photos.length === 0}
          sx={{ mt: 2 }}
        >
          Submit Photos
        </Button>
      )}
      <TipsPage />
    </div>
  );
}

export default PhotoUpload;
