import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import SignIn from './SignIn';
import SignUp from './SignUp';
import PhotoHistory from './PhotoHistory';
import { auth } from './firebase';
import {
  Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Grid, Card, Button,
  CircularProgress, Typography, Modal, Box, AppBar, Toolbar, CardActionArea
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import logo from './firstpic.png';
import HistoryIcon from '@mui/icons-material/History';
import HomeIcon from '@mui/icons-material/Home';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PasswordReset from './PasswordReset'; 
import ResetPassword from './ResetPassword';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PaymentsPage from './PaymentsPage'; 
import ThankYouPage from './ThankYouPage';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import VerifyEmail from './VerifyEmail';
import ReactGA4 from 'react-ga4'; // Import ReactGA4
import useDocumentTitle from './hooks/useDocumentTitle';
import usePageTracking from './hooks/usePageTracking'; 
import LandingPage from './LandingPage';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import RootRedirectHandler from './RootRedirectHandler';
import TipsPage from './TipsPage';
import Blog from './Blog'; // Import the Blog component
import BlogPost from './BlogPost'; // Import the BlogPost component
import BioCollectionForm from './BioCollectionForm';
import ChatInterface from './ChatInterface';
import ProfileDisplay from './ProfileDisplay';
import AppNavigation from './AppNavigation';
import BioDisplay from './BioDisplay';
import IntroductionPage from './IntroductionPage';
import PhotoUpload from './PhotoUpload'; 
import PhotoDisplay from './PhotoDisplay'; 
import BioPaymentPage from './BioPaymentPage'; 
import ChatPaymentPage from './ChatPaymentPage'; 
import PhotoPaymentPage from './PhotoPaymentPage'; 
import CheckOutComplete from './CheckOutComplete';
import CheckOutComplete2 from './CheckOutComplete2';
import CheckOutComplete3 from './CheckOutComplete3';
import CancelSubscriptionPage from './CancelSubscriptionPage'; 
import BioHistory from './BioHistory'; 
import ProfileInputPage from './ProfileInputPage';
import CameraAltIcon from '@mui/icons-material/CameraAlt'; // For "Profile Input"
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum'; // For "Photo History"
import SettingsIcon from '@mui/icons-material/Settings'; // Import SettingsIcon for account management

const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';
const stripePromise = loadStripe("YOUR_STRIPE_PUBLISHABLE_KEY");

// Initialize Google Analytics
ReactGA4.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

const drawerWidth = 240;


const ProtectedRoute = ({ children, user }) => {
    if (!user) {
        return <Navigate to="/signin" />;
    }
    return children;
};
function Logo() {
  const location = useLocation(); // Now useLocation is called within a component rendered by Router
  const excludedPaths = ['/', '/terms-of-service', '/privacy-policy','/blog']; // Add paths where the logo shouldn't appear

  // Check if the current pathname is in the excludedPaths array
  if (excludedPaths.includes(location.pathname) || location.pathname.startsWith('/blog/')) {
    return null; // Don't render the logo
  }

  

  return (
    <img src={logo} alt="FirstPic Logo" style={{ height: '150px' }} />
  );
}
function AppRoutes({ user }) {
  return (
    <Routes>
      <Route path="/" element={<RootRedirectHandler />} /> 
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/profile-input" element={<ProtectedRoute user={user}><ProfileInputPage user={user} /></ProtectedRoute>} />
      <Route path="/history" element={<ProtectedRoute user={user}><PhotoHistory user={user} /></ProtectedRoute>} />
      <Route path="/reset-password" element={<PasswordReset />} />
      <Route path="/reset-password-confirm" element={<ResetPassword />} />
      <Route path="/thank-you" element={<ProtectedRoute user={user}><ThankYouPage /></ProtectedRoute>} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/tips" element={<TipsPage />} />
      <Route path="/blog" element={<Blog />} /> 
      <Route path="/blog/:id" element={<BlogPost />} /> {/* New route for individual blog posts */}  
      <Route path="/welcome" element={<ProtectedRoute user={user}><IntroductionPage userId={user?.uid} /></ProtectedRoute>} />
      <Route path="/bio-writer" element={
      <ProtectedRoute user={user}>
          <AppNavigation>
            <BioCollectionForm userId={user?.uid} />
          </AppNavigation>
        </ProtectedRoute>
      } />      
      <Route path="/chat-interface" element={
        <ProtectedRoute user={user}>
          <AppNavigation>
            <ChatInterface userId={user?.uid} />
          </AppNavigation>
        </ProtectedRoute>
      } />
      <Route path="/bio-display/:bioId" element={<ProtectedRoute user={user}><BioDisplay user={user} /></ProtectedRoute>} />
      <Route path="/photo-upload" element={
        <ProtectedRoute user={user}>
          <AppNavigation>
            <PhotoUpload userId={user?.uid} />
          </AppNavigation>
        </ProtectedRoute>
      } />
      <Route path="/photo-display" element={
      <ProtectedRoute user={user}>
         <AppNavigation><PhotoDisplay user={user} /></AppNavigation>
        </ProtectedRoute>
      } />
      <Route path="/bio-payments-page" element={<ProtectedRoute user={user}><BioPaymentPage userId={user?.uid} /></ProtectedRoute>} />
      <Route path="/chat-payment-page" element={<ProtectedRoute user={user}><ChatPaymentPage userId={user?.uid} /></ProtectedRoute>} />
      <Route path="/photos-payments-page" element={<ProtectedRoute user={user}><PhotoPaymentPage userId={user?.uid} /></ProtectedRoute>} />
      <Route path="/checkout-complete" element={<ProtectedRoute user={user}><CheckOutComplete userId={user?.uid}/></ProtectedRoute>} />
       <Route path="/checkout-complete2" element={<ProtectedRoute user={user}><CheckOutComplete2 userId={user?.uid}/></ProtectedRoute>} />
       <Route path="/checkout-complete3" element={<ProtectedRoute user={user}><CheckOutComplete3 userId={user?.uid}/></ProtectedRoute>} />
      <Route path="/account-details" element={<ProtectedRoute user={user}><CancelSubscriptionPage userId={user?.uid}/></ProtectedRoute>} />
      <Route path="/bio-history" element={<ProtectedRoute user={user}>
      <AppNavigation><BioHistory userId={user?.uid}/></AppNavigation>
        </ProtectedRoute>} />
    </Routes>
  );
}


const StyledDrawer = styled(Drawer)(({ theme }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  width: drawerWidth,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: '#6A5ACD',
    color: '#ffffff',
    boxSizing: 'border-box',
  },
}));

function Sidebar({ open, handleDrawerClose, handleDrawerToggle,isUserSubscribed }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const hideSidebar = location.pathname === '/' || location.pathname === '/signin'  || location.pathname === '/blog' || location.pathname.startsWith('/blog/') || location.pathname === '/signup' || location.pathname.startsWith('/reset-password') || location.pathname.startsWith('/reset-password-confirm') || location.pathname.startsWith('/privacy-policy') || location.pathname.startsWith('/terms-of-service') || location.pathname.startsWith('/verify-email');

  // Immediately return null if the sidebar should be hidden
  if (hideSidebar) {
    return null;
  }

  const handleSignOut = () => {
    auth.signOut()
      .then(() => {
        console.log("Sign out successful");
        navigate('/signin');
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  return (
    <div>
      {!open && !isDesktop && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          sx={{ marginRight: theme.spacing(2), marginLeft: theme.spacing(2) }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <StyledDrawer 
        variant={isDesktop ? 'permanent' : 'temporary'}
        open={open} // Use the `open` prop directly
        onClose={handleDrawerClose}
      >
        {!isDesktop && (
          <IconButton onClick={handleDrawerClose} sx={{ alignSelf: 'flex-start', margin: theme.spacing(1) }}>
            <ChevronLeftIcon />
          </IconButton>
        )}
        <List>
    {/* Photo Upload */}
    <ListItem button component="a" href="/photo-upload">
        <ListItemIcon><HomeIcon /></ListItemIcon>
        <ListItemText primary="Home" />
    </ListItem>
    {/* Photo History */}
    <ListItem button component="a" href="/history">
        <ListItemIcon><PhotoAlbumIcon /></ListItemIcon>
        <ListItemText primary="Photo History" />
    </ListItem>

    {/* Bio History */}
    <ListItem button component="a" href="/bio-history">
        <ListItemIcon><HistoryIcon /></ListItemIcon>
        <ListItemText primary="Bio History" />
    </ListItem>

    {/* Profile Input */}
 {!isUserSubscribed && (
    <ListItem button component="a" href="/profile-input">
        <ListItemIcon><CameraAltIcon /></ListItemIcon>
        <ListItemText primary="Rate a Photo (Free)" />
    </ListItem>
   )}

    {/* Upgrade */}
 {!isUserSubscribed && (
          <ListItem button component="a" href="/chat-payment-page">
              <ListItemIcon><ArrowUpwardIcon /></ListItemIcon>
              <ListItemText primary="Upgrade" />
          </ListItem>
        )}

          {/* Account Management */}
      <ListItem button component="a" href="/account-details">
          <ListItemIcon><SettingsIcon /></ListItemIcon>
          <ListItemText primary="Account" />
      </ListItem>

    {/* Sign Out */}
    <ListItem button onClick={handleSignOut}>
        <ListItemIcon><ExitToAppIcon /></ListItemIcon>
        <ListItemText primary="Sign Out" />
    </ListItem>
</List>
      </StyledDrawer>
    </div>
  );
}

function App() {
  useDocumentTitle('Profile Input');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Define isMobile here
  const [open, setOpen] = useState(!isMobile);
  const [user, setUser] = useState(null);
  const [isUserSubscribed, setIsUserSubscribed] = useState(false); // Moved inside the App component

  const [loading, setLoading] = useState(true); // Added loading state


  const handleDrawerClose = () => {
    setOpen(false);
  };

 const handleDrawerToggle = () => {
  setOpen(!open);
  };

  const checkSidebarVisibility = () => {
    // Define routes where the sidebar should be hidden
    const hideSidebarPaths = [
      '/', '/signin', '/signup', '/reset-password', '/reset-password-confirm',
      '/privacy-policy', '/terms-of-service', '/verify-email','/blog'
    ];

    // Get the current pathname from the window location
    const pathname = window.location.pathname;

    // Return true if the sidebar should be visible
    return !hideSidebarPaths.includes(pathname);
  };

const handleUserSignIn = (userId) => {
  fetch(`${backendURL}/api/signin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId }),
  })
  .then(response => response.json())
  .then(data => {
    setUser({
      uid: data.userId,
      gender: data.gender,
    });

    // Now fetch subscription status
    fetch(`${backendURL}/api/user-details/${data.userId}`)
      .then(response => response.json())
      .then(subscriptionData => {
        setIsUserSubscribed(subscriptionData.isSubscribed);
      })
      .catch(error => console.error('Error fetching subscription status:', error));
  })
  .catch(error => {
    console.error('Error during sign-in:', error);
  });
};




useEffect(() => {
  // This function will be called on app load and whenever the auth state changes
  const unsubscribe = auth.onAuthStateChanged((currentUser) => {
    if (currentUser) {
      // User is signed in.
      setUser(currentUser);
      handleUserSignIn(currentUser.uid); // Ensure this doesn't create an endless loop
    } else {
      // User is signed out.
      setUser(null);
    }
    setLoading(false);
  });

  // Unsubscribe from the listener when the component unmounts
  return () => unsubscribe();
}, []);



  if (loading) {
    return <CircularProgress />; // Show loading indicator while checking user state
  }

  return (
    <Router>
      <AppBar position="static" style={{
        backgroundColor: 'transparent', 
        boxShadow: 'none', 
        zIndex: theme.zIndex.drawer + 1,
        marginLeft: open && !isMobile ? `${drawerWidth}px` : 0,
        padding: 0, // Reduce padding
      }}>
        <Toolbar style={{ minHeight: 56 }}> 
          <Logo />
        </Toolbar>
      </AppBar>
<Sidebar open={open} handleDrawerClose={handleDrawerClose} handleDrawerToggle={handleDrawerToggle} isUserSubscribed={isUserSubscribed} />
            <Box 
        component="main" 
        sx={{
          flexGrow: 1, 
          p: 3,
          paddingTop: 0,
        }}
        className={checkSidebarVisibility() && open && !isMobile ? 'sidebar-visible' : ''}
      >
        <AppRoutes user={user} />
      </Box>
    </Router>
  );
}

export default App;