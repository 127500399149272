import React from 'react';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Link,
  ThemeProvider,
  useTheme,
  useMediaQuery
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useNavigate } from 'react-router-dom';
import logo from './firstpic.png';
import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
  },
});

const Header = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const appBarHeight = theme.spacing(8);

  const commonButtonStyle = {
    backgroundColor: '#ED4A4A', // Red background
    color: 'white', // White text
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#D14343', // Darker red on hover
    },
    fontSize: '0.875rem', // Adjust font size if necessary
  };

  const mobileButtonStyle = {
    ...commonButtonStyle, // Spread common styles here
    minWidth: '80px', // Smaller width for mobile
    height: '35px', // Smaller height for mobile
    fontSize: '0.75rem', // Smaller font size for mobile
    padding: '6px 12px', // Adjust padding for mobile
    margin: theme.spacing(0.5), // Add margin around the buttons to avoid touching
  };

  const handleLoginClick = () => navigate('/signin');
  const handleSignUpClick = () => navigate('/signup');

  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar position="fixed" style={{ backgroundColor: '#333', height: appBarHeight }}>
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <img src={logo} alt="FirstPic Logo" style={{ height: '135px', width: 'auto' }} />
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Link href="https://www.instagram.com/firstpicai/" color="inherit" target="_blank" rel="noopener noreferrer">
              <IconButton color="inherit">
                <InstagramIcon />
              </IconButton>
            </Link>
            {!isMobile && (
              <Button
                variant="contained"
                onClick={() => navigate('/blog')}
                sx={{
                  ...commonButtonStyle,
                  minWidth: '100px',
                  height: '40px',
                  marginLeft: theme.spacing(1),
                }}
              >
                Blog
              </Button>
            )}
            <Button
              variant="contained"
              onClick={handleLoginClick}
              sx={isMobile ? mobileButtonStyle : {
                ...commonButtonStyle, // Spread common styles here
                minWidth: '100px', // Ensure minimum width for the button
                height: '40px', // Fixed height for uniformity
                marginLeft: theme.spacing(1),
              }}
            >
              Login
            </Button>

            <Button
              variant="contained"
              onClick={handleSignUpClick}
              sx={isMobile ? mobileButtonStyle : {
                ...commonButtonStyle, // Spread common styles here
                minWidth: '100px', // Ensure minimum width for the button
                height: '40px', // Fixed height for uniformity
                marginLeft: theme.spacing(1),
              }}
            >
              Sign Up
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;
