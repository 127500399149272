import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, TextField, Typography, styled, CircularProgress, IconButton, InputAdornment, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { AttachFile } from '@mui/icons-material';
import imageCompression from 'browser-image-compression';
import useDocumentTitle from './hooks/useDocumentTitle';
import usePageTracking from './hooks/usePageTracking';

const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

const Input = styled('input')({
  display: 'none',
});

function ChatInterface({ userId }) {
  const navigate = useNavigate();
  const location = useLocation();
  const handleChange = (event, newValue) => {
    navigate(newValue);
  };

  useDocumentTitle('Chat Interface');
  usePageTracking();
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [imageData, setImageData] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    checkSubscription();
  }, [userId]); // Adding userId as a dependency to re-run if userId changes
  
  const checkSubscription = async () => {
    const response = await fetch(`${backendURL}/api/user-details/${userId}`);
    if (!response.ok) {
      console.error('Network response was not ok');
      setIsProcessing(false);
      return;
    }
    const { isSubscribed } = await response.json();
    setIsSubscribed(isSubscribed);
  };
  
  const handleTextChange = (e) => {
    setInputText(e.target.value);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 1, 
          maxWidthOrHeight: 1024, 
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onload = () => {
          setImageData(reader.result);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error('Error compressing image:', error);
      }
    }
  };

  const handleCopyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setSnackbarMessage('Text copied to clipboard!');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
      setSnackbarMessage('Failed to copy text.');
      setSnackbarOpen(true);
    }
  };

  const handleSendMessage = async () => {
    if (!inputText && !imageData) {
      alert('Please enter a message or upload an image.');
      return;
    }
  
    // If the user is subscribed, skip the message limit check.
    if (!isSubscribed) {
      setIsProcessing(true);
      try {
        const response = await fetch(`${backendURL}/api/generateResponseForConversation`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userId,
            text: inputText,
            imageData: imageData,
            previousMessages: messages, // Sending previousMessages to the backend
          }),
        });
  
        if (response.ok) {
          const data = await response.json();
          const newMessages = [...messages];
          if (inputText) {
            newMessages.push({ role: 'user', content: inputText });
          }
          if (imageData) {
            newMessages.push({ role: 'user', content: 'Image sent', imageData: imageData });
          }
          newMessages.push({ role: 'system', content: data.response });
          setMessages(newMessages);
          setInputText('');
          setImageData(null);
        } else if (response.status === 402) {
          setOpenDialog(true);
        } else {
          console.error('Network response was not ok.');
        }
      } catch (error) {
        console.error('Error sending message:', error);
      } finally {
        setIsProcessing(false);
      }
    } else {
      setIsProcessing(true); // Make sure this is set to true immediately when the function is called

      // If subscribed, process message without checking limits.
      try {
        const response = await fetch(`${backendURL}/api/generateResponseForConversation`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userId,
            text: inputText,
            imageData: imageData,
            previousMessages: messages, // Sending previousMessages to the backend
          }),
        });
  
        if (response.ok) {
          const data = await response.json();
          const newMessages = [...messages];
          if (inputText) {
            newMessages.push({ role: 'user', content: inputText });
          }
          if (imageData) {
            newMessages.push({ role: 'user', content: 'Image sent', imageData: imageData });
          }
          newMessages.push({ role: 'system', content: data.response });
          setMessages(newMessages);
          setInputText('');
          setImageData(null);
        } else {
          console.error('Network response was not ok.');
        }
      } catch (error) {
        console.error('Error sending message:', error);
      } finally {
        setIsProcessing(false);
      }
    }
  };
  

  return (
    <Box 
    sx={{ 
      maxWidth: '600px', 
      margin: 'auto', 
      padding: '16px', 
      backgroundColor: '#f5f5f5', 
      borderRadius: '8px',
      '& .MuiBox-root': { // Targeting all Box components inside, adjust as needed
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0
      }
    }}
  >      <Typography variant="h5" sx={{ mb: 2, color: 'black' }}>
        MatchGPT
      </Typography>
      <Typography variant="h6" sx={{ mb: 2, color: 'black' }}>
        Enter a message you received or upload a screenshot of a conversation or match profile and we'll write your next message.
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, backgroundColor: '#ffffff', padding: '16px', borderRadius: '8px' }}>
        {messages.map((message, index) => (
          <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: message.role === 'user' ? 'flex-end' : 'flex-start' }}>
            {message.imageData ? (
              <img src={message.imageData} alt="Uploaded by user" style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover', borderRadius: '8px' }} />
            ) : (
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    maxWidth: '75%',
                    padding: '8px',
                    borderRadius: '8px',
                    backgroundColor: message.role === 'user' ? '#1976d2' : '#e0e0e0',
                    color: message.role === 'user' ? '#fff' : '#000',
                    wordWrap: 'break-word',
                  }}
                >
                  {message.content}
                </Typography>
                {message.role === 'system' && (
                  <Button onClick={() => handleCopyText(message.content)} size="small" sx={{ mt: 1 }}>
                    Copy
                  </Button>
                )}
              </Box>
            )}
          </Box>
        ))}
      </Box>
      <Dialog
  open={openDialog}
  onClose={() => setOpenDialog(false)}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{"Upgrade Required"}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      You have reached your weekly free message limit. Upgrade now for unlimited messaging!
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenDialog(false)} color="primary">
      Cancel
    </Button>
    <Button onClick={() => navigate('/chat-payment-page')} color="primary" autoFocus>
      Upgrade
    </Button>
  </DialogActions>
</Dialog>


      <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Type your message"
          variant="outlined"
          fullWidth
          value={inputText}
          onChange={handleTextChange}
          multiline
          rows={4}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <label htmlFor="upload-photo">
                  <Input accept="image/*" id="upload-photo" type="file" onChange={handleImageChange} />
                  <IconButton component="span">
                    <AttachFile />
                  </IconButton>
                </label>
              </InputAdornment>
            ),
          }}
          sx={{ backgroundColor: '#fff', color: '#000' }}
        />
        {imageData && (
          <Box sx={{ mt: 1 }}>
            <img src={imageData} alt="Uploaded" style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px' }} />
          </Box>
        )}
        <Button variant="contained" color="primary" onClick={handleSendMessage} disabled={isProcessing}>
          {isProcessing ? <CircularProgress size={24} /> : 'Send'}
        </Button>
      </Box>
    </Box>
  );
}
export default ChatInterface;
