import React, { useState, useEffect } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from './hooks/useDocumentTitle';
import usePageTracking from './hooks/usePageTracking';

const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    background: {
      default: '#121212',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderColor: '#90caf9',
          '& label.Mui-focused': {
            color: '#90caf9',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#90caf9',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#90caf9',
            },
            '&:hover fieldset': {
              borderColor: '#90caf9',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#90caf9',
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
  },
});

const FormComponent = ({ userId }) => {
  useDocumentTitle('Bio Collection');
  usePageTracking();
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const savedStep = JSON.parse(localStorage.getItem('formCurrentStep'));
    if (savedStep !== null) {
      setCurrentStep(savedStep);
      localStorage.removeItem('formCurrentStep');
    }
  }, []);

  const initializeFormData = () => {
    const pendingBio = JSON.parse(localStorage.getItem('pendingBio'));
    if (pendingBio && pendingBio.prompt) {
      const promptSections = pendingBio.prompt.split('\n').filter(section => section.trim() !== '');
      const formData = {
        freeTimeActivities: '',
        partnerExpectations: '',
        uniqueSkill: '',
        travelExperience: '',
        conversationTopics: '',
        work: '',
        appProfile: pendingBio.platform || '',
      };
      promptSections.forEach(section => {
        const [, key, value] = section.match(/(\d+)\.\s*(.*)/) || [];
        switch (parseInt(key)) {
          case 1: formData.freeTimeActivities = value.replace('Free Time Activities: ', ''); break;
          case 2: formData.partnerExpectations = value.replace('Looking for in a Partner: ', ''); break;
          case 3: formData.uniqueSkill = value.replace('Unique Skill: ', ''); break;
          case 4: formData.travelExperience = value.replace('Best Travel Experience: ', ''); break;
          case 5: formData.conversationTopics = value.replace('Enjoy Talking About: ', ''); break;
          case 6: formData.work = value.replace('Work: ', ''); break;
        }
      });
      return formData;
    } else {
      return {
        freeTimeActivities: '',
        partnerExpectations: '',
        uniqueSkill: '',
        travelExperience: '',
        conversationTopics: '',
        work: '',
        appProfile: '',
      };
    }
  };

  const [formData, setFormData] = useState(initializeFormData);
  const [isProcessing, setIsProcessing] = useState(false);

  const isCurrentFieldValid = () => {
    let fieldIsValid = false;
    switch (currentStep) {
      case 0:
        fieldIsValid = formData.freeTimeActivities.trim() !== '' && formData.freeTimeActivities.length <= 225;
        break;
      case 1:
        fieldIsValid = formData.partnerExpectations.trim() !== '' && formData.partnerExpectations.length <= 225;
        break;
      case 2:
        fieldIsValid = formData.uniqueSkill.trim() !== '' && formData.uniqueSkill.length <= 225;
        break;
      case 3:
        fieldIsValid = formData.travelExperience.trim() !== '' && formData.travelExperience.length <= 225;
        break;
      case 4:
        fieldIsValid = formData.conversationTopics.trim() !== '' && formData.conversationTopics.length <= 225;
        break;
      case 5:
        fieldIsValid = formData.work.trim() !== '' && formData.work.length <= 225;
        break;
      case 6:
        fieldIsValid = formData.appProfile.trim() !== '';
        break;
      default:
        fieldIsValid = false;
    }
    return fieldIsValid;
  };

  const handleSubmit = async () => {
    setIsProcessing(true);

    const requiredFields = [
      'freeTimeActivities', 
      'partnerExpectations', 
      'uniqueSkill',
      'travelExperience', 
      'conversationTopics', 
      'work', 
      'appProfile'
    ];
    const isFormValid = requiredFields.every(field => formData[field].trim() !== '');
    const isLengthValid = Object.values(formData).every(value => value.length <= 225);

    if (!isFormValid || !isLengthValid) {
      alert('Please ensure all fields are filled and do not exceed 225 characters.');
      setIsProcessing(false);
      return;
    }

    const bioDataForSubmission = {
      userId: userId,
      platform: formData.appProfile,
      prompt: `
        1. Free Time Activities: ${formData.freeTimeActivities}
        2. Looking for in a Partner: ${formData.partnerExpectations}
        3. Unique Skill: ${formData.uniqueSkill}
        4. Best Travel Experience: ${formData.travelExperience}
        5. Enjoy Talking About: ${formData.conversationTopics}
        6. Work: ${formData.work}
        7. Selected App: ${formData.appProfile}`,
    };

    localStorage.setItem('pendingBio', JSON.stringify(bioDataForSubmission));
    localStorage.setItem('formCurrentStep', JSON.stringify(currentStep));

    const response = await fetch(`${backendURL}/api/user-details/${userId}`);
    if (!response.ok) {
      console.error('Network response was not ok');
      setIsProcessing(false);
      return;
    }
    const { isSubscribed } = await response.json();

    if (isSubscribed) {
      try {
        const submitResponse = await fetch(`${backendURL}/api/submitBio`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(bioDataForSubmission)
        });

        const submitData = await submitResponse.json();
        if (submitResponse.ok) {
          navigate(`/bio-display/${submitData.bioId}`);
        } else {
          console.error('Bio submission was not successful:', submitData);
        }
      } catch (error) {
        console.error('Error submitting bio:', error);
      }
    } else {
      navigate('/bio-payments-page');
    }

    setIsProcessing(false);
  };

  const nextStep = () => {
    if (isCurrentFieldValid()) {
      if (currentStep < 6) {
        setCurrentStep(currentStep + 1);
      } else {
        handleSubmit();
      }
    } else {
      alert('This field is required. Please provide a value before proceeding.');
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const renderStep = () => {
    const examples = {
      freeTimeActivities: "Be descriptive and focus on the where/when instead of just listing activities. For example: Playing rec league softball in McCarren Park, running along the west side highway at sunrise, finding new york city's best chocolate chip cookie, reading mystery novels",
      partnerExpectations: "Here's an example: Someone who is empathetic, has a growth mindset, lives an active and healthy lifestyle, and is family oriented",
      uniqueSkill: "Think about something you can playfully make fun of yourself about. For example: I lead my volleyball team in serves sent into the East River",
      travelExperience: "Focus here on one or two experiences and provide sufficient detail. For example: Learning to surf at Maria's Beach in Puerto Rico, where the waves and sunsets are equally long and beautiful.",
      conversationTopics: "Again, be descriptive. For example: My favorite episodes of Curb Your Enthusiasm, the Philadelphia Eagles, and which pizzeria has the best slice in NYC",
      work: "Example: Product Manager at HealthTech Company, building software to help people lead healthier lives.",
      appProfile: "",
    };

    const renderTextField = (name, label, rows = 4) => (
      <div>
        <TextField
          inputProps={{ maxLength: 225 }}
          helperText={
            <React.Fragment>
              <br />
              {`${examples[name]}`}
            </React.Fragment>
          }
          label={`${label} (${formData[name].length}/225)`}
          variant="outlined"
          fullWidth
          multiline
          rows={rows}
          name={name}
          value={formData[name]}
          onChange={handleChange}
        />
      </div>
    );

    switch (currentStep) {
      case 0: return renderTextField("freeTimeActivities", "What activities do you do in your free time?");
      case 1: return renderTextField("partnerExpectations", "What are you looking for in a partner?");
      case 2: return renderTextField("uniqueSkill", "What interesting or unusual skill do you have?");
      case 3: return renderTextField("travelExperience", "What was your best travel experience?");
      case 4: return renderTextField("conversationTopics", "What do you enjoy talking about?");
      case 5: return renderTextField("work", "What do you do for work?", 2);
      case 6:
        return (
          <FormControl component="fieldset">
            <FormLabel component="legend">Choose your app profile</FormLabel>
            <RadioGroup
              name="appProfile"
              value={formData.appProfile}
              onChange={handleChange}
            >
              <FormControlLabel value="Tinder" control={<Radio />} label="Tinder" />
              <FormControlLabel value="Bumble" control={<Radio />} label="Bumble" />
              <FormControlLabel value="Hinge" control={<Radio />} label="Hinge" />
            </RadioGroup>
          </FormControl>
        );
      default: return <div>Form Complete</div>;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <Box sx={{ width: '100%', mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            Progress
          </Typography>
          <LinearProgress
            variant="determinate"
            value={(currentStep / 6) * 100}
            sx={{
              height: 10,
              borderRadius: 5,
              '& .MuiLinearProgress-bar': {
                borderRadius: 5,
                backgroundColor: '#90caf9',
              },
            }}
          />
        </Box>
        <form onSubmit={(e) => e.preventDefault()}>
          {renderStep()}
          <div style={{ marginTop: '20px' }}>
            {currentStep > 0 && (
              <Button onClick={prevStep} startIcon={<ArrowBackIosIcon />}>
                Back
              </Button>
            )}
            {currentStep < 6 ? (
              <Button onClick={nextStep} endIcon={<ArrowForwardIosIcon />} style={{ marginLeft: '10px' }}>
                Next
              </Button>
            ) : (
              <Button onClick={handleSubmit} endIcon={<ArrowForwardIosIcon />} style={{ marginLeft: '10px' }} disabled={isProcessing}>
                Submit
              </Button>
            )}
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};

export default FormComponent;
