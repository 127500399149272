import React, { useState, useEffect } from 'react';
import { Container, Card, CardContent, Typography, CircularProgress, CardMedia, CardActionArea } from '@mui/material';
import Header from './Header';
import { Link } from 'react-router-dom';
import useDocumentTitle from './hooks/useDocumentTitle'; // Adjust the path as necessary
import usePageTracking from './hooks/usePageTracking'; // Adjust the path as necessary
const Blog = () => {
    useDocumentTitle('Blog');
    usePageTracking();

    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch('/api/ghost-posts')
            .then(response => response.json())
            .then(data => {
                setArticles(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching articles:', error);
                setLoading(false);
            });
    }, []);

    const getPreviewText = (htmlContent) => {
        const plainText = htmlContent.replace(/<[^>]+>/g, ''); // Strip HTML tags
        return plainText.substring(0, 100) + '...'; // Return first 100 characters
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
        <Header />
        <Container maxWidth="md">
            <Typography variant="h2" component="h1" gutterBottom>
                Posts
            </Typography>
            {articles.map(article => (
                <Link to={`/blog/${article.id}`} key={article.id} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Card style={{ marginBottom: '20px' }}>
                        <CardActionArea>
                            {article.feature_image && (
                                <CardMedia
                                    component="img"
                                    image={article.feature_image}
                                    alt={article.title}
                                />
                            )}
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    {article.title}
                                </Typography>
                                <Typography variant="body2" component="p">
                                    {article.html ? getPreviewText(article.html) : article.excerpt}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Link>
            ))}
        </Container>
        </>
    );
}

export default Blog;
