// HowItWorks.js
import React from 'react';
import { Box, Typography, Paper, Step, Stepper, StepLabel } from '@mui/material';
import UploadIcon from '@mui/icons-material/CloudUpload';
import ScoreIcon from '@mui/icons-material/Assessment';
import RankIcon from '@mui/icons-material/FormatListNumbered';
import UpdateIcon from '@mui/icons-material/Update';

const steps = [
  {
    label: 'Submit a screenshot of your match or a conversation',
    description: 'Get a suggested message back and make moving things off app easy.',
    icon: <UploadIcon />,
  },
  {
    label: 'Upload your profile pics',
    description: 'Bulk upload your dating app photos.',
    icon: <UploadIcon />,
  },
  {
    label: 'Answer a few quick questions',
    description: 'Tell us a bit about yourself.',
    icon: <ScoreIcon />,
  },
  {
    label: 'Build your profile',
    description: 'Build a tailor-made proifle for Tinder, Bumble or Hinge.',
    icon: <ScoreIcon />,
  },
  {
    label: 'See how your photos rank',
    description: 'See how to rank your photos and how they compare to other users to understand where you stand in the dating scene.',
    icon: <RankIcon />,
  },
  {
    label: 'Optimize Your Dating Profile',
    description: 'Use your photo analysis and dating app content to enhance your profile. Boost your chances of matching with your first pick!',
    icon: <UpdateIcon />,
  },
];

const HowItWorks = () => {
  return (
    <Box sx={{ width: '100%', my: 4 }}>
      <Stepper activeStep={-1} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              icon={step.icon}
              optional={
                <Typography variant="caption" display="block">
                  {step.description}
                </Typography>
              }
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default HowItWorks;
