import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, CircularProgress, Grid, Card, CardMedia, CardContent, Dialog, Button } from '@mui/material';
import useDocumentTitle from './hooks/useDocumentTitle';
import usePageTracking from './hooks/usePageTracking';

const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

const PhotoDisplay = ({ user }) => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  useDocumentTitle('Photo Display');
  usePageTracking();

  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted
    const maxAttempts = 10; // Maximum number of attempts
    let attempts = 0;

    const fetchPhotos = async (storedPhotoIds) => {
      if (!user) return; // Ensure user is available
      const userId = user.uid; // Extract userId from the user object

      if (!isMounted) return;
      setLoading(true);
      try {
        const photosResponse = await fetch(`${backendURL}/api/photos`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: userId, photoIds: JSON.parse(storedPhotoIds) }),
        });

        if (!photosResponse.ok) {
          throw new Error(`Failed to fetch photos, status: ${photosResponse.status}`);
        }

        const photosData = await photosResponse.json();
        if (photosData && photosData.photos) {
          const sortedPhotos = photosData.photos.sort((a, b) => b.score - a.score);
          setPhotos(sortedPhotos);
        } else {
          setError('Photos not found.');
        }
      } catch (error) {
        setError('Failed to fetch data.');
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchPhotosWithRetry = () => {
      attempts++;
      const storedPhotoIds = localStorage.getItem('photoIds');
      if (storedPhotoIds) {
        fetchPhotos(storedPhotoIds);
      } else if (attempts < maxAttempts && isMounted) {
        setTimeout(fetchPhotosWithRetry, 2000); // Retry every 2 seconds
      } else {
        if (isMounted) setError('Failed to load photos after several attempts.');
      }
    };

    fetchPhotosWithRetry();

    return () => {
      isMounted = false; // Clean up function to set isMounted to false when the component unmounts
    };
  }, [user]);

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditPhotos = () => {
    navigate("/photo-upload");
  };
  const renderPercentileText = (photo) => {
    // Assuming `photo.userGender` exists and contains the gender. Adjust according to your actual data structure.
          const genderText = user && user.gender === 'male' ? 'men' : (user && user.gender === 'female' ? 'women' : 'users');
    return `Your photo scored better than ${photo.percentileScore.toFixed(2)}% of ${genderText}'s photos.`;
  };
  if (loading) return <Container><CircularProgress /></Container>;
  if (error) return <Container><Typography>{error}</Typography></Container>;

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" gutterBottom style={{ marginTop: '20px' }}>Photos</Typography>
      <Typography variant="subtitle1" gutterBottom>Order Your Photos Like This:</Typography>
      
      <Grid container spacing={2}>
        {photos.map((photo, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={photo.thumbnailUrl || photo.imageUrl}
                alt="User Photo"
                style={{ objectFit: 'contain' }}
                onClick={() => handleOpen(photo.imageUrl)}
              />
              <CardContent style={{ height: '200px', overflowY: 'auto' }}>
                <Typography gutterBottom variant="h6">#{index + 1} Photo Score: {(photo.score * 10).toFixed(2)}%</Typography>
                <Typography variant="subtitle1" color="text.secondary">
          <Typography variant="subtitle1">{renderPercentileText(photo)}</Typography>
                            </Typography>
                {photo.feedback && <Typography variant="body2">Feedback: {photo.feedback}</Typography>}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <img src={selectedImage} alt="Expanded" style={{ width: '100%' }} />
      </Dialog>
    <Button variant="contained" color="primary" onClick={handleEditPhotos} style={{ margin: '10px' }}>
        Edit Photos
      </Button>
    </Container>
  );
};

export default PhotoDisplay;
