import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import useDocumentTitle from './hooks/useDocumentTitle';
import usePageTracking from './hooks/usePageTracking';

const CheckOutComplete2 = () => {
  useDocumentTitle('Thank You for Your Purchase - Photos!');
  usePageTracking();
  const navigate = useNavigate();

  useEffect(() => {
    // Navigate to the photo-display page
    navigate('/photo-display');
  }, [navigate]);

  return (
    <Box style={{ textAlign: 'center', marginTop: '100px' }}>
      <Typography variant="h4">Thank You for Your Purchase!</Typography>
      <Typography variant="subtitle1">Your photos will be processed shortly.</Typography>
      <CircularProgress style={{ marginTop: '20px' }} />
    </Box>
  );
};

export default CheckOutComplete2;
