import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, CircularProgress, Paper } from '@mui/material';
import { Helmet } from 'react-helmet';
import Header from './Header';
import usePageTracking from './hooks/usePageTracking';
import useDocumentTitle from './hooks/useDocumentTitle'; // Import useDocumentTitle
import './App.css';

const BlogPost = () => {

    usePageTracking();
    useDocumentTitle('Blog Post');

    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        fetch(`/api/ghost-posts/${id}`)
            .then(response => response.json())
            .then(data => {
                setPost(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching post:', error);
                setLoading(false);
            });

        // Load the Ghost sign-up form script
        const script = document.createElement('script');
        script.src = "https://cdn.jsdelivr.net/ghost/signup-form@~0.1/umd/signup-form.min.js";
        script.async = true;
        script.setAttribute('data-button-color', "#ff1a1a");
        script.setAttribute('data-button-text-color', "#FFFFFF");
        script.setAttribute('data-site', "https://firstpic.ghost.io/");
        document.body.appendChild(script);

        // Clean up
        return () => {
            document.body.removeChild(script);
        };
    }, [id]);

    if (loading) {
        return <CircularProgress />;
    }

    if (!post) {
        return <Typography variant="h5">Post not found</Typography>;
    }
    
    return (
        <>
            <Header />
            <Container maxWidth="md">
                <Helmet>
                    <title>{post.title}</title>
                    <meta name="description" content={post.custom_excerpt || post.excerpt} />
                    {post.tags && post.tags.map(tag => (
                        <meta key={tag.id} name="keywords" content={tag.name} />
                    ))}
                </Helmet>
                <Paper style={{ backgroundColor: '#f5f5f5', color: '#333', padding: '20px', marginBottom: '20px' }}>
                    <Typography
    variant="h2"
    component="h1"
    sx={{
        fontSize: {
            xs: '1.5rem', // for small devices
            sm: '2.125rem', // for medium devices and up
            md: '3.75rem', // for large devices and up, you can adjust these values as needed
        }
    }}
>
    {post.title}
</Typography>

                    <div dangerouslySetInnerHTML={{ __html: post.html }} />
                </Paper>
                <Typography variant="h5" style={{ textAlign: 'center', marginTop: '20px' }}>FirstPic</Typography>
                <Typography variant="body1" style={{ textAlign: 'center', marginBottom: '20px' }}>
                    Tips, Tutorials and Inspiration on using AI to optimize your online dating profile
                </Typography>
                {/* Placeholder for the sign-up form with additional CSS for limiting width */}
 
            </Container>
        </>

    );
};

export default BlogPost;
