import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Tabs, Tab } from '@mui/material';

function AppNavigation({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event, newValue) => {
    navigate(newValue);
  };

  return (
    <Box sx={{ maxWidth: '600px', margin: 'auto', padding: '16px', width: '100%' }}>
      <Tabs
        value={location.pathname}
        onChange={handleChange}
        variant="scrollable" // Enables horizontal scrolling
        scrollButtons="auto" // Show scroll buttons automatically when needed
        allowScrollButtonsMobile // Allows scroll buttons on mobile devices
        sx={{
          mb: 4,
          '& .MuiTabs-indicator': {
            backgroundColor: '#1976d2', // A Material-UI blue for the indicator
          },
          '& .MuiTab-root': { // Styles applied to each tab
            color: '#1976d2', // Blue text for all tabs
            borderColor: 'rgba(25, 118, 210, 0.5)', // Semi-transparent blue outline
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '4px',
            margin: '0 4px', // Adds spacing between tabs
            '&.Mui-selected': { // Styles for the selected tab
              color: '#fff', // White text for selected tab
              backgroundColor: '#1976d2', // Blue background for selected tab
            }
          }
        }}
      >
        <Tab label="Photo Analysis" value="/photo-upload" />
        <Tab label="Bio Writer" value="/bio-writer" />
        <Tab label="Messaging" value="/chat-interface" />
      </Tabs>
      {/* Render children components which change per route */}
      {children}
    </Box>
  );
}

export default AppNavigation;
