import React from 'react';
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  IconButton,
  Toolbar,
  Typography,
  Link,
  ThemeProvider,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useNavigate } from 'react-router-dom';
import logo from './firstpic.png';
import profileImage from './images/first-pic-landing-page.jpg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Helmet } from 'react-helmet';
import HowItWorks from './HowItWorks';
import FAQComponent from './FAQComponent';
import Header from './Header';
import useDocumentTitle from './hooks/useDocumentTitle'; // Adjust the path as necessary
import usePageTracking from './hooks/usePageTracking'; // Adjust the path as necessary
import SeenOn from './SeenOn'; // Adjust the import path as needed
import aiFire from './images/aifire.jpg';
import cyberNews from './images/cybernews-logo.png';
import hackerNews from './images/Hacker-News-logo.jpg';


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
  },
});

const logos = [
  { src: aiFire, alt: 'AIFire Logo' },
  { src: cyberNews, alt: 'Cybernews Logo' },
  { src: hackerNews, alt: 'Hacker News Logo' },
  // Add more logos as needed
];


const LandingPage = () => {
    useDocumentTitle('FirstPic: The 1st AI that builds your dating app profiles.');

    usePageTracking();

  const navigate = useNavigate();
  const handleSignUpClick = () => navigate('/signup');
  const handleLoginClick = () => navigate('/signin');
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const appBarHeight = theme.spacing(8);
// Define a style object for mobile screens
const commonButtonStyle = {
  backgroundColor: '#ED4A4A', // Red background
  color: 'white', // White text
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#D14343', // Darker red on hover
  },
  fontSize: '0.875rem', // Adjust font size if necessary
};

// Update the mobileButtonStyle to include the common styles and additional mobile-specific adjustments
const mobileButtonStyle = {
  ...commonButtonStyle, // Spread common styles here
  minWidth: '80px', // Smaller width for mobile
  height: '35px', // Smaller height for mobile
  fontSize: '0.75rem', // Smaller font size for mobile
  padding: '6px 12px', // Adjust padding for mobile
  margin: theme.spacing(0.5), // Add margin around the buttons to avoid touching
};

  return (
    <ThemeProvider theme={darkTheme}>
      <Helmet>
        <title>FirstPic - Optimize Your Dating Profiles</title>
        <meta name="description" content="FirstPic - AI trained to find your best dating app pics, write your bio and prompts to maximize matches, and respond to messages." />
      </Helmet>
      <CssBaseline />
      <Header /> {/* Use the Header component */}
<main style={{ paddingTop: appBarHeight, minHeight: `calc(100vh - ${appBarHeight})`, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            textAlign: 'left',
            px: 4,
            py: { xs: 2, md: 8 },
            flexBasis: { md: '50%' },
          }}
        >
          <Typography variant={isMobile ? 'h5' : 'h4'} component="h1" gutterBottom>
            FirstPic: The 1st AI that builds your dating app profiles and writes your messages.
          </Typography>
          <Typography variant={isMobile ? 'h6' : 'h5'} component="h2" gutterBottom>
            AI-powered magic that makes it easy to land the best matches and turn them into dates.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSignUpClick}
            sx={{
              mb: 2,
              fontSize: isMobile ? '1rem' : '1.25rem',
              padding: isMobile ? '10px 20px' : '6px 16px',
              backgroundColor: '#ED4A4A',
              fontWeight: 'bold',
              color: 'white',
              '&:hover': {
                backgroundColor: '#D14343',
              },
            }}
          >
            Get Started
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate('/blog')}
            sx={{
                mb: 2,
                fontSize: isMobile ? '1rem' : '1.25rem',
                padding: isMobile ? '10px 20px' : '6px 16px',
                backgroundColor: 'white', // Set button color to white
                fontWeight: 'bold',
                color: '#ED4A4A', // Set text color to red
                '&:hover': {
                backgroundColor: '#f0f0f0', // Light gray for hover
                color: '#D14343', // Darker red on hover
                },
            }}
            >
            Blog
            </Button>
  <Box sx={{ mt: 2 }}>
    <a href="https://topai.tools/t/firstpic?ref=embed" target="_blank" rel="noopener noreferrer">
      <img
        src="https://topai.tools/assets/img/topai.tools.gif"
        style={{ maxWidth: '300px', maxHeight: '80px' }}
        alt="FirstPic Featured on topAI.tools"
      />
    </a>
  </Box>
<SeenOn logos={logos} />


        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            backgroundColor: '#121212',
            color: 'white',
            padding: theme.spacing(3),
          }}
        >
          {/* How It Works Column */}
          <Box
            sx={{
              flexBasis: { md: '50%' },
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: theme.spacing(2),
            }}
          >
            <Typography variant="h4" component="h2" gutterBottom>
              How It Works
            </Typography>
            <HowItWorks />
          </Box>

          {/* Image Column */}
        <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto', // Centers the Box
        }}
        >
        <img
            src={profileImage}
            alt="Image of the FirstPic dating profile photo optimization in action"
            style={{
            maxWidth: '90%', // Preserve the original maximum width
            maxHeight: '70vh', // Preserve the original maximum height
            width: 'auto', // Maintain aspect ratio
            height: 'auto', // Maintain aspect ratio
            border: '5px solid #C0C0C0', // Silver color border
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
            borderRadius: '20px', // Rounded corners
            }}
        />
        </Box>


        </Box>
               <Box
          sx={{
            width: '100%', // Ensure the width matches the rest of the content
            maxWidth: 'lg', // Set a maximum width if needed
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // Align items in the center
            textAlign: 'center', // Center the text
            px: 4, // Horizontal padding
            py: { xs: 2, md: 8 }, // Vertical padding
            margin: 'auto', // Center the box
          }}
        >
          <Typography variant="h4" component="h2" gutterBottom>
            Frequently Asked Questions
          </Typography>
          {/* FAQ Component */}
          <FAQComponent />
        </Box>
      </main>

      <footer
        style={{
          textAlign: 'center',
          padding: '20px 0',
          backgroundColor: '#333',
          color: 'white',
          width: '100%',
          position: 'relative',
        }}
      >
        <Button onClick={() => navigate('/terms-of-service')} color="inherit">
          Terms of Use
        </Button>
        |
        <Button onClick={() => navigate('/privacy-policy')} color="inherit">
          Privacy Policy
        </Button>
      </footer>
    </ThemeProvider>
  );
};

export default LandingPage;
