import React, { useState } from 'react';
import { Button, Card, CardContent, Typography, Grid } from '@mui/material';
import useDocumentTitle from './hooks/useDocumentTitle';
import usePageTracking from './hooks/usePageTracking';
import { useNavigate } from 'react-router-dom';

const ChatPaymentPage = ({ userId }) => {
    const [selectedPriceId, setSelectedPriceId] = useState('price_1PNH5BIAtJPdVgDDSrL2rHQg');

    useDocumentTitle('AI Dating Coach - Chat');
    usePageTracking();
    const navigate = useNavigate();

    const createCheckoutSession = (priceId) => {
        fetch('/api/create-checkout-session-chat', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, priceId }),
        })
        .then(response => response.json())
        .then(data => {
            const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
            stripe.redirectToCheckout({ sessionId: data.sessionId });
        })
        .catch(error => console.error('Error:', error));
    };

    const handleBackClick = () => {
        navigate('/chat-interface');
    };

    const packages = [
        { id: 'price_1PNH5BIAtJPdVgDDSrL2rHQg', name: 'FirstPic Weekly', price: '$3.99/week', description: 'Weekly subscription' },
        { id: 'price_1PNH4NIAtJPdVgDDS7zLonbO', name: 'FirstPic Monthly', price: '$6.99/month', description: 'Monthly subscription' },
        { id: 'price_1PNH3jIAtJPdVgDDquyQsAtx', name: 'FirstPic Yearly', price: '$34.99/year', description: 'Yearly subscription' }
    ];

    return (
        <Grid container spacing={2} justifyContent="center" sx={{ p: 3 }}>
            {packages.map((pkg) => (
                <Grid item xs={12} sm={6} md={4} key={pkg.id}>
                    <Card
                        raised
                        sx={{
                            minHeight: 200,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            backgroundColor: selectedPriceId === pkg.id ? '#f3f3f3' : '#ffffff',
                            transform: selectedPriceId === pkg.id ? 'scale(1.05)' : 'scale(1)',
                            transition: 'transform .2s',
                            cursor: 'pointer'
                        }}
                        onClick={() => setSelectedPriceId(pkg.id)}
                    >
                        <CardContent>
                            <Typography variant="h5" component="div" gutterBottom>
                                {pkg.name}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {pkg.description}
                            </Typography>
                            <Typography variant="h5">
                                {pkg.price}
                            </Typography>
                        </CardContent>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            onClick={(event) => {
                                event.stopPropagation();
                                createCheckoutSession(pkg.id); // Pass the correct package ID
                            }}
                            sx={{ margin: 2 }}
                        >
                            Checkout
                        </Button>
                    </Card>
                </Grid>
            ))}
            <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={handleBackClick}
                sx={{ marginTop: 2 }}
            >
                Back
            </Button>
        </Grid>
    );
};

export default ChatPaymentPage;
