import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Import Firestore

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDiHZ7pf7vtaoiPZfsAc_mOPfGv_1sC3kg",
  authDomain: "score-afee4.firebaseapp.com",
  projectId: "score-afee4",
  storageBucket: "score-afee4.appspot.com",
  messagingSenderId: "725036592928",
  appId: "1:725036592928:web:8c2ef1bf2444e6fff0f145",
  measurementId: "G-2TY6LSSZN9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app); // Rename firestore to db

// Google Sign-In Functionality
const provider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    // This gives you a Google Access Token which you can use to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    // ...
    return { user, token }; // You can return user data or navigate to a different page here.
  } catch (error) {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
    console.error("Error signing in with Google: ", errorCode, errorMessage);
    // Optionally, handle or display the error message to your user
  }
};

export { app, analytics, auth, db, signInWithGoogle };
