import React from 'react';
import { Box, Button, Typography, Grid, Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from './hooks/useDocumentTitle';
import usePageTracking from './hooks/usePageTracking';

const IntroductionPage = () => {
  useDocumentTitle('Welcome to FirstPic');
  usePageTracking();
  const navigate = useNavigate();

  const handlePhotoUploadClick = () => {
    navigate('/photo-upload');
  };

  const handleBioWriterClick = () => {
    navigate('/bio-writer');
  };

  const handleMessagingClick = () => {
    navigate('/chat-interface');
  };

  return (
    <Grid container spacing={2} direction="column" alignItems="center" sx={{ p: 3 }}>
      <Grid item xs={12}>
        <Box sx={{ maxWidth: { xs: 350, sm: 500, md: 700, lg: 900 }, textAlign: 'center', mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            Welcome to FirstPic!
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Our app offers three main features to help you succeed on dating apps:
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Photo Analysis:</strong> Upload your photos, and our AI will analyze them to give you the best possible feedback on how to order them for your dating profile.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Profile Content Writing:</strong> Get high-quality bio and prompt content written by our AI to improve your matches and interactions on dating apps.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Messaging:</strong> Upload a photo of a match's profile or a conversation you're having, and we'll suggest an opening line or your next response.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Card raised sx={{ minHeight: 200, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={handlePhotoUploadClick}
                sx={{ mb: 2 }}
              >
                Start Photo Analysis
              </Button>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={handleBioWriterClick}
              >
                Start Bio Writer
              </Button>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={handleMessagingClick}
              >
                Start Messaging
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default IntroductionPage;
