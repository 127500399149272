import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import LandingPage from './LandingPage'; // Import your LandingPage component if needed

function RootRedirectHandler() {
  let [searchParams] = useSearchParams();
  let navigate = useNavigate();
  let mode = searchParams.get('mode');
  let oobCode = searchParams.get('oobCode');

  useEffect(() => {
    if (mode === 'resetPassword' && oobCode) {
      navigate(`/reset-password-confirm?oobCode=${oobCode}`, { replace: true });
    } else if (mode === 'verifyEmail' && oobCode) {
      navigate(`/verify-email?oobCode=${oobCode}`, { replace: true });
    }
    // You can add more conditions here for other modes if needed
  }, [mode, oobCode, navigate]);

  // Render your normal landing page if no special parameters are found
  return <LandingPage />;
}

export default RootRedirectHandler;
