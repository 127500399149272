import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // For programmatic navigation
import { styled, useTheme } from '@mui/material/styles'; // For styling
import { 
  Box, 
  Button, 
  Card, 
  Grid, 
  Typography, 
  CardActionArea, 
  CircularProgress // If you use it for loading indication
} from '@mui/material'; // Material-UI components
import { auth } from './firebase'; // Assuming the path to your Firebase setup
import usePageTracking from './hooks/usePageTracking'; // Assuming the path to your hook
import useDocumentTitle from './hooks/useDocumentTitle'; // Adjust the path as necessary
import TipsPage from './TipsPage'; // Adjust the path as necessary to where your TipsPage component is located relative to this file

// You mentioned using `fetchRemainingRatings` and making other fetch calls, so ensure:
// - The backendURL is defined if it's not hardcoded in the fetch calls.
const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

// If you're using environmental variables or any other external configurations, make sure they are correctly set up in your project.




function ProfileInputPage({ user }) {
    useDocumentTitle('Photo Ratings');

    usePageTracking();
    const navigate = useNavigate();
    const [photo, setPhoto] = useState('');
    const [score, setScore] = useState('');
    const [processingMessage, setProcessingMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [percentile, setPercentile] = useState('');
    const [feedback, setFeedback] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
    }, []); 
    const Input = styled('input')({
        display: 'none',
    });
    
    // Initialize remainingRatings here to prevent undefined errors
    const [remainingRatings, setRemainingRatings] = useState(undefined);

    // Check if the score button should be disabled
    const isScoreButtonDisabled = !photo || remainingRatings === 0;



    const handlePhotoChange = (e) => {
        setPhoto(e.target.files[0]);
        setScore('');
        setPercentile('');
        setProcessingMessage('');
        setFeedback([]); // Reset feedback here as well
        setTimeout(() => {
            // Your existing code...
        }, 5000);
    };

    const resetPhotoAndScore = () => {
        setPhoto('');
        setScore('');
        setPercentile('');
        setProcessingMessage('');
        setFeedback([]); // Reset feedback to an empty array
    };



    const theme = useTheme();





    const StyledPageContainer = styled(Box)(({ theme }) => ({
        flexGrow: 1,
        paddingTop: 0, // Reduced from 3
        paddingBottom: theme.spacing(1), // Reduced from 3
        minHeight: 'auto', // Full view height
        backgroundAttachment: 'fixed',
        backgroundColor: '#121212',
        color: '#ffffff',
        '& .MuiCard-root': {
            borderRadius: theme.shape.borderRadius, // Rounded corners for the card
            // Intensify the shadow by increasing the blur and optionally the spread
            boxShadow: `0px 8px 16px 2px ${theme.palette.grey[800]}`, // Larger blur and spread for a deeper shadow
            backgroundColor: '#221E2B',
            color: 'white',
            margin: 0, // Remove default margin
            width: '100%', // Full width
            maxWidth: 'none', // Override maxWidth to ensure it can expand fully
            // No need to duplicate boxShadow, remove one
            // Add more styles as needed
        },
        // ... more styles
    }));

    const renderPhotoUploadArea = () => (
    <CardActionArea sx={{ maxWidth: 345, margin: 'auto', borderRadius: '20px', overflow: 'hidden', backgroundColor: 'transparent', padding: '0px' }}>
        <label htmlFor="upload-photo">
            <Input accept="image/*" id="upload-photo" type="file" onChange={handlePhotoChange} />
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 400,
                border: '1px dashed grey',
                borderRadius: '20px',
                boxSizing: 'border-box',
                width: '100%', // The Box now takes 100% of the CardActionArea's width
                backgroundColor: 'transparent',
                '&:hover': {
                },
                // No margin or padding here to avoid expanding the Box size
            }}>
                {photo ? (
                    <img
                        src={URL.createObjectURL(photo)}
                        alt="Uploaded Profile"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '20px',
                        }} // Image takes the full space of the Box
                    />
                ) : (
                    <Typography variant="subtitle1">
                        Click to upload image
                    </Typography>
                )}
            </Box>
        </label>
    </CardActionArea>
    );


    useEffect(() => {
        if (auth.currentUser && auth.currentUser.uid) {
            // Fetch remaining ratings
            fetchRemainingRatings(auth.currentUser.uid);
        }
    }, [auth.currentUser]); // Dependency on auth.currentUser

    const fetchRemainingRatings = (userId) => {
        fetch(`${backendURL}/api/getRemainingRatings`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId }),
        })
        .then(response => response.json())
        .then(data => {
            setRemainingRatings(data.remainingRatings);
        })
        .catch(error => {
            console.error('Error fetching remaining ratings:', error);
            // Handle the error appropriately
        });
    };

    const handleSubmit = () => {
        setScore('');
        setPercentile('');
        setFeedback([]);
// Assuming photoUUID is generated the same way as before
        const photoUUID = crypto.randomUUID();
        setIsProcessing(true); // This will trigger the display of the "Analyzing..." text

        const formData = new FormData();
        formData.append('userId', auth.currentUser.uid);
        formData.append('photo', photo);
        formData.append('photoId', JSON.stringify(photoUUID)); // Stringify photoId

    
        fetch(`${backendURL}/api/submitProfile`, {
            method: 'POST',
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`Network response was not ok (${response.status})`);
            }
            const contentType = response.headers.get('content-type');
            if (!contentType || !contentType.includes('application/json')) {
                throw new TypeError("Received response is not in JSON format");
            }
            return response.json();
        })
        .then(data => {
            console.log('Received from backend:', data);
            // Assuming the score is between 0 and 1, multiply by 10 for a scale of 1 to 10
            let formattedScore = (parseFloat(data.score) * 10).toFixed(2); // Two decimal places
            setScore(formattedScore); // Set the formatted score to the state
            setProcessingMessage('We successfully processed your photo!');
            if (data.percentileScore) {
                setPercentile(data.percentileScore.toFixed(2)); // Assuming percentile is a number
                }
            setFeedback(data.feedback || []); // Set the feedback, default to an empty array if none is provided
        })
        .catch(error => {
            console.error('Error submitting profile', error);
            setProcessingMessage(`Error processing your photo. Please try again. [${error.message}]`);
        })
        .finally(() => {
            setIsProcessing(false);
        });

         fetch(`${backendURL}/api/decrementRating`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId: auth.currentUser.uid }),
        })
        .then(response => response.json())
        .then(data => setRemainingRatings(data.remainingRatings))
        .catch(error => console.error('Error decrementing rating:', error));
    };

       const renderScoreDisplay = () => {
           let scoreColor = score > 69.99 ? 'green' : (score > 60.00 ? 'orange' : 'white');
           return (
           <Typography variant="h6" component="div" sx={{ color: scoreColor }}>
           Your photo scored: <strong>{`${score}%`} {score > 74.99 && '🔥'}</strong>
           </Typography>
  );
};


    const renderPercentileDisplay = () => {
        const genderText = user && user.gender === 'male' ? 'men' : (user && user.gender === 'female' ? 'women' : 'users');
        return (
            <Typography variant="h6" component="div" sx={{ mt: 2 }}>
                Your photo scored better than <strong>{percentile}%</strong> of {genderText}'s photos.
            </Typography>
        );
    };

    const renderFeedback = () => {
        if (!feedback) return null; // Don't render if no feedback

        return (
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
                <strong>Photo Feedback:</strong> {feedback}
            </Typography>
        );
    };





    const renderScoreButton = () => (
        <Button
            variant="outlined"
            onClick={handleSubmit}
            disabled={isScoreButtonDisabled}
            sx={{
                borderRadius: '50%',
                minWidth: '56px',
                height: '56px',
                border: '2px solid',
                color: photo ? '#5BF8C5' : '#D3D3D3', // Green when photo is uploaded, grey otherwise
                borderColor: photo ? '#5BF8C5' : '#D3D3D3',
                '&:hover': {
                    backgroundColor: 'transparent',
                    borderColor: photo ? '#5BF8C5' : '#D3D3D3',
                },
                ...(isScoreButtonDisabled && {
                    color: '#D3D3D3', // Disabled text color
                    borderColor: '#D3D3D3', // Disabled border color
                    '&.Mui-disabled': {
                        color: '#D3D3D3', // Override Material-UI default disabled color
                        borderColor: '#D3D3D3',
                    },
                }),
            }}
        >
            Score
        </Button>
    );



    const renderRemainingRatingsOrUpgradeButton = () => {
        if (remainingRatings > 0) {
            return (
                <Typography variant="body2">
                    Remaining Ratings: {remainingRatings}
                </Typography>
            );
        } else {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/payments')}
                >
                    Get More Ratings
                </Button>
            );
        }
    };


 return (
    <StyledPageContainer>
        <Box sx={{ flexGrow: 1, padding: 0,backgroundColor: 'transparent', }}>
        {renderRemainingRatingsOrUpgradeButton()}
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Card sx={{ padding: theme.spacing(2), textAlign: 'center', borderRadius: '20px' ,overflow: 'hidden'}}> {/* Rounded corners for the card */}
                        <Typography variant="body1" paragraph>
                           Find the best photo for your dating profile.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            1. Upload, 2. Click score, 3. Rank your best pic first ☝️!
                        </Typography>

                        {renderPhotoUploadArea()}
                        
                        
                        {
                isProcessing ? (
                    <Typography variant="h6" component="div" sx={{ mt: 2, color: 'white', animation: 'flashAnimation 2s infinite' }}>
                        Analyzing...
                    </Typography>
                ) : (
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', marginTop: 2 }}>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={resetPhotoAndScore}
                            sx={{
                                borderRadius: '50%',
                                minWidth: '56px',
                                height: '56px',
                                border: '2px solid',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    border: '2px solid',
                                    color: '#E7258E',
                                },
                            }}
                        >
                            Reset
                        </Button>
                       
                        {renderScoreButton()}
                    </Box>
                )
            }


                        {score && renderScoreDisplay()}
                        {percentile && renderPercentileDisplay()} {/* Display percentile information */}
                        {feedback.length > 0 && renderFeedback()} {/* Render feedback if available */}



                        
                    </Card>
                </Grid>
            </Grid>
        </Box>
                <TipsPage />

    </StyledPageContainer>
    );
}


export default ProfileInputPage;
