import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getAuth, applyActionCode } from 'firebase/auth';
import { createTheme, ThemeProvider, CssBaseline, Container, Typography } from '@mui/material';
import useDocumentTitle from './hooks/useDocumentTitle';
import usePageTracking from './hooks/usePageTracking'; 

const EmailVerification = () => {
    useDocumentTitle('Verify Email');
    usePageTracking();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate(); // Hook for navigation
    const [verificationStatus, setVerificationStatus] = useState('Verifying...');
    const auth = getAuth();

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#90caf9', // Adjust primary color as needed
            },
            secondary: {
                main: '#f48fb1', // Adjust secondary color as needed
            },
            background: {
                default: '#121212', // Dark background color
                paper: '#1e1e1e', // Dark paper color
            },
        },
    });

    useEffect(() => {
        const oobCode = searchParams.get('oobCode');

        if (oobCode) {
            applyActionCode(auth, oobCode)
                .then(() => {
                    setVerificationStatus('Your email has been successfully verified.');
                    // Redirect to signin page after a short delay
                    setTimeout(() => {
                        navigate('/signin');
                    }, 3000); // 3 seconds delay
                })
                .catch((error) => {
                    setVerificationStatus('Error verifying email. The link may be invalid or expired.');
                });
        }
    }, [searchParams, auth, navigate]);

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Container component="main" maxWidth="sm" style={{ marginTop: '20px', textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom>Email Verification</Typography>
                <Typography variant="body1">{verificationStatus}</Typography>
            </Container>
        </ThemeProvider>
    );
};

export default EmailVerification;
