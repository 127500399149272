import React, { useEffect } from 'react'; // Import useEffect here
import { Box, Typography, CssBaseline, ThemeProvider } from '@mui/material';
import Header from './Header';
import useDocumentTitle from './hooks/useDocumentTitle'; // Adjust the path as necessary
import usePageTracking from './hooks/usePageTracking'; // Adjust the path as necessary


const TermsOfService = () => {
  useDocumentTitle('Terms of Service');
  usePageTracking();

 
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  }, []);
  return (
<React.Fragment>
      <CssBaseline />
      <Header /> {/* Use the Header component */}

      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="body1" paragraph>Last updated: January 25th, 2024</Typography>
        <Typography variant="body1" paragraph>
          <strong>1. Acceptance of Terms</strong><br />
          FirstPic.ai ("we," "us," or "our") provides its service to you, subject to the following Terms of Service ("ToS"), 
          which may be updated by us from time to time without notice to you. By accessing and using this website, 
          you accept and agree to be bound by these ToS.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>2. Description of Service</strong><br />
          FirstPic.ai provides a web-based service that allows users to create profiles, log in, make payments, 
          and upload photos for evaluation by our machine learning algorithm (the "Service"). Our Service includes, 
          but is not limited to, any service and content FirstPic.ai makes available to or performs for you.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>3. Privacy Policy</strong><br />
          Our Privacy Policy explains how we treat your personal data and protect your privacy when you use our Service. 
          By using our Service, you agree that FirstPic.ai can use such data in accordance with our privacy policies.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>4. User Accounts</strong><br />
          You are responsible for maintaining the confidentiality of your account and password and for restricting 
          access to your computer, and you agree to accept responsibility for all activities that occur under your 
          account or password.
        </Typography>
        <Typography variant="body1" paragraph>
        <strong>5. Payments and Billing</strong><br />
        FirstPic.ai uses Stripe for payment processing services. By using the Stripe payment processing services 
        you agree to the <a href="https://stripe.com/legal" target="_blank" rel="noopener noreferrer">Stripe Terms of Service</a>.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>6. User Content</strong><br />
          Users may submit content, including photos, to the Service. By uploading content, you agree to grant 
          FirstPic.ai a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to use your 
          content for purposes including, but not limited to, the development and improvement of machine learning 
          models and other features of the Service.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>7. User Conduct</strong><br />
          You agree not to use the Service to:
          <ul>
            <li>(a) upload any content that is unlawful, harmful, threatening, abusive, harassing, tortious, 
                defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, 
                ethnically or otherwise objectionable;</li>
            <li>(b) harm minors in any way;</li>
            <li>(c) impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation 
                with a person or entity;</li>
            <li>(d) "stalk" or otherwise harass another; and/or</li>
            <li>(e) collect or store personal data about other users in connection with the prohibited conduct 
                and activities set forth in paragraphs (a) through (d) above.</li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>8. Disclaimer of Warranties</strong><br />
          You expressly understand and agree that your use of the Service is at your sole risk. The Service and all 
          products and services delivered to you through the Service are (except as expressly stated by us) provided 
          'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, 
          either express or implied.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>9. Limitation of Liability</strong><br />
          You expressly understand and agree that FirstPic.ai shall not be liable for any direct, indirect, incidental, 
          special, consequential or exemplary damages, including but not limited to, damages for loss of profits, 
          goodwill, use, data or other intangible losses (even if FirstPic.ai has been advised of the possibility 
          of such damages), resulting from the use or the inability to use the Service.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>10. General Information</strong><br />
          The ToS constitute the entire agreement between you and FirstPic.ai and govern your use of the Service, 
          superseding any prior agreements between you and FirstPic.ai. The ToS and the relationship between you and 
          FirstPic.ai shall be governed by the laws of the State without regard to its conflict of law provisions.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>11. Contact Information</strong><br />
          If you have any questions about these Terms of Service, please contact us at [your contact information].
        </Typography>
        <Typography variant="body1" paragraph>
          Please review this document carefully. By accessing or using FirstPic.ai, you are bound to the terms 
          described herein and all terms incorporated by reference. If you do not agree to all of these terms, 
          do not use FirstPic.ai.
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default TermsOfService;
