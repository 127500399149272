import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import useDocumentTitle from './hooks/useDocumentTitle';
import usePageTracking from './hooks/usePageTracking'; 

const ThankYouPage = () => {
  useDocumentTitle('Thank You');
  usePageTracking();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/profile-input');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Box style={{ textAlign: 'center', marginTop: '100px' }}>
      <Typography variant="h4">Thank You for Your Purchase!</Typography>
      <Typography variant="subtitle1">Your additional ratings are now available!</Typography>
      <CircularProgress style={{ marginTop: '20px' }} />
    </Box>
  );
};

export default ThankYouPage;
