import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from './firebase';
import { TextField, Button, Typography, Container, Box, CssBaseline, Avatar, Link } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useDocumentTitle from './hooks/useDocumentTitle'; // Adjust the path as necessary
import usePageTracking from './hooks/usePageTracking'; // Adjust the path as necessary

const defaultTheme = createTheme();

export default function PasswordReset() {
    useDocumentTitle('Reset Password Request');
    usePageTracking();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#90caf9', // Adjust primary color as needed
            },
            secondary: {
                main: '#f48fb1', // Adjust secondary color as needed
            },
            background: {
                default: '#121212', // Dark background color
                paper: '#1e1e1e', // Dark paper color
            },
        },
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('Check your email for the password reset link');
        } catch (error) {
            setMessage('Error resetting password: ' + error.message);
        }
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Reset Password
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField 
                            label="Email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required 
                            fullWidth
                            margin="normal"
                        />
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            Send Reset Link
                        </Button>
                        {message && <Typography sx={{ mt: 2 }}>{message}</Typography>}
                        <Link href="/signin" variant="body2" sx={{ mt: 2, display: 'block', textAlign: 'center' }}>
                            Back to Sign In
                        </Link>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
