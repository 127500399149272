import React, { useState, useEffect } from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Box, Dialog } from '@mui/material';
import useDocumentTitle from './hooks/useDocumentTitle'; // Adjust the path as necessary
import usePageTracking from './hooks/usePageTracking'; // Adjust the path as necessary

const PhotoHistory = ({ user }) => {
    useDocumentTitle('Photo History');
    usePageTracking();
    const [photos, setPhotos] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        // Ensure user object is defined and has uid
        if (user && user.uid) {
            fetch(`/api/photoHistory/${user.uid}`) // Use `user.uid` to fetch photo history
                .then(response => response.json())
                .then(data => {
                    setPhotos(data.photos.reverse());
                })
                .catch(error => console.error('Error fetching photo history:', error));
        }
    }, [user]); // Depend on `user` to re-fetch if user object changes


    const handleOpen = (image) => {
        setSelectedImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
const renderPercentileText = (photo) => {
  // Assuming `photo.userGender` exists and contains the gender. Adjust according to your actual data structure.
        const genderText = user && user.gender === 'male' ? 'men' : (user && user.gender === 'female' ? 'women' : 'users');
  return `Your photo scored better than ${photo.percentileScore.toFixed(2)}% of ${genderText}'s photos.`;
};
    const renderContent = () => {
        if (photos && photos.length > 0) {
            return photos.map((photo, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                        <CardMedia
                            component="img"
                            height="350"
                            image={photo.thumbnailUrl || photo.imageUrl}
                            alt="User Photo"
                            onClick={() => handleOpen(photo.imageUrl)}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                               Photo Score: {(photo.score * 10).toFixed(2)}%
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary">
          <Typography variant="subtitle1">{renderPercentileText(photo)}</Typography>
                            </Typography>
                            {/* Display the feedback */}
                            {photo.feedback && (
                                <Typography variant="body2" color="text.secondary">
                                    Feedback: {photo.feedback}
                                </Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            ));
        } else {
            return (
                <Box sx={{ width: '100%', textAlign: 'center', marginTop: 2 }}>
                    <Typography variant="h6">
                        You haven't scored any photos yet!
                    </Typography>
                </Box>
            );
        }
    };

    return (
        <Grid container spacing={2}>
            {renderContent()}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="image-dialog-title"
                aria-describedby="image-dialog-description"
            >
                <img src={selectedImage} alt="Expanded" style={{ width: '100%' }} />
            </Dialog>
        </Grid>
    );
};

export default PhotoHistory;
