import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, CircularProgress, Button } from '@mui/material';
import useDocumentTitle from './hooks/useDocumentTitle'; // Adjust the path as necessary
import usePageTracking from './hooks/usePageTracking'; // Adjust the path as necessary

const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

const BioDisplay = ({ user }) => {
  const { bioId } = useParams();
  const [bio, setBio] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const navigate = useNavigate();
  useDocumentTitle('Bio Display');
  usePageTracking();

  useEffect(() => {
    const fetchBio = async () => {
      setLoading(true);
      try {
        const bioResponse = await fetch(`${backendURL}/api/bio/${bioId}`);
        const bioData = await bioResponse.json();
        if (bioData.bio) {
          setBio(bioData.bio);
        } else {
          setError('Bio not found.');
        }
      } catch (error) {
        setError('Failed to fetch bio data.');
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBio();
  }, [bioId]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(bio.generatedText).then(() => {
      alert("Bio copied to clipboard!");
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  const handleEditBio = () => {
    localStorage.removeItem('formCurrentStep'); // This clears the saved step
    navigate("/bio-writer");
  };

  if (loading) return <Container><CircularProgress /></Container>;
  if (error) return <Container><Typography>{error}</Typography></Container>;

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>Bio Details</Typography>
      <Typography variant="h6">{bio.platform}</Typography> {/* Display the platform */}

      <Typography style={{ whiteSpace: 'pre-wrap' }}>
        {bio.generatedText}
      </Typography>
      <Button variant="contained" color="primary" onClick={handleCopyToClipboard} style={{ margin: '10px' }}>
        Copy to Clipboard
      </Button>
      <Button variant="contained" color="primary" onClick={handleEditBio} style={{ margin: '10px' }}>
        Edit Bio
      </Button>
    </Container>
  );
};

export default BioDisplay;
